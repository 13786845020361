import AdvText from "@components/data/text";
import AdvGenericDialog from "@components/dialogs/generic/generic";
import AdvWeiterDialog from "@components/dialogs/weiter";
import AdvDatePicker from "@components/inputs/datepicker";
import {
    TAdvDatePickerDisplayFormat,
    TAdvDatePickerRangeType,
    TAdvDatePickerValueFormat,
} from "@components/inputs/datepicker/utils";
import AdvDropdown from "@components/inputs/dropdown-new/dropdown";
import AdvTextInput from "@components/inputs/text-input/text-input";
import AdvModal from "@components/layout/modal/modal";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import {
    dpClientSetEditDatGlobalTrans,
    dpClientSetIsEditableGlobalTrans,
} from "@data/dataprovider/data-provider-client";
import { EContractEvent, recoilContractEvent } from "@data/dynamic-page";
import { LAN } from "@data/language/strings";
import { buildPageIDForVariableID } from "@data/parameters";
import { useWebOrder } from "@feature/web-order";
import { Dialog, ProgressIndicator } from "@fluentui/react";
import useAdvError from "@hooks/dialogs/useAdvError";
import useAdvInfo from "@hooks/dialogs/useAdvInfo";
import useAdvToast from "@hooks/dialogs/useAdvToast";
import {
    AdvValueBindingDefaultValue,
    EAdvValueBinderType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useAdvLayoutEffect } from "@hooks/react-overload/useAdvLayoutEffect";
import useAdvRecoilState from "@hooks/recoil-overload/useAdvRecoilState";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvSetRecoilState from "@hooks/recoil-overload/useAdvSetRecoilState";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvEvent } from "@hooks/useAdvEvent";
import { TSuccessClass, useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import { TPageInfo, dynamicPageAtomName } from "@pages/dynamic";
import { pageInstanceContext } from "@pages/dynamic/instanceIndexContext";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import { advcatch, advlog } from "@utils/logging";
import {
    buildPageParserParam,
    buildQueryAsStr,
    buildUniquePageID,
    gDefaultIgnoreParamKeys,
} from "@utils/page-parser";
import fileDownload from "js-file-download";
import { nanoid } from "nanoid";
import React, { useContext, useMemo, useRef, useState } from "react";
import { atom } from "recoil";
import {
    TContractID,
    buildUniqueContractID,
    contractFieldsAtom,
    gActiveContracts,
    gContracts,
} from "./contracts";
import {
    EAdvContractEventType,
    TAdvContractEvent,
    TAdvContractEventCallback,
    TAdvContractFieldDataValueChangedNtf,
} from "./types";

type TContractSQLProviderInterfaceRecord = {
    FieldName: string;
    FieldValue: any;
};

type TContractFieldDataRecord = {
    FieldName: string;
    FieldValue: Array<any>;
};

type TContractFieldData = {
    Data: TContractFieldDataRecord;
    NotifyOnInterfaceEntryValueChange: string;
    NotifyProviderName: string;
};

export type TAdvContractEventStart = TAdvContractEvent & {
    InitialData: Array<TContractSQLProviderInterfaceRecord>;
    InitialFieldData: Array<TContractFieldData>;
    ProviderList: Array<string>;
};

export type TAdvContractEventQuestion = TAdvContractEvent & {
    Question: string;
    Yes: string;
    No: string;
    Hint?: string;
};

export type TAdvContractEventFulfilled = TAdvContractEvent & {
    Data: string | undefined;
    Extra: Array<any>;
    ProviderList: Array<string>;
};

export type TAdvContractEventCancelled = TAdvContractEvent & {
    Data: string | undefined;
    ProviderList: Array<string>;
    UserInitiatedCancel: boolean;
    AsFatalError: boolean;
};

export type TAdvContractEventProgress = TAdvContractEvent & {
    ID: string;
    Progress: number;
    MaxProgress: number;
    Text: string;
};

export type TAdvContractEventFieldData = TAdvContractEvent & {
    FieldName: string;
    Data: Array<any>;
};

type TEditDataEntry = {
    FieldName: string;
    Data: any;
    DataType: any;
    EditIndex: number;
};

export type TAdvContractEventSetEditData = TAdvContractEvent & {
    EditData: Array<TEditDataEntry>;
};

type TContractInitialDataChangedEntry = {
    FieldName: string;
    DataOld: any;
    DataNew: any;
};

export type TAdvContractEventInitialDataChanged = TAdvContractEvent & {
    Question: string;
    Yes: string;
    No: string;
    ChangedData: Array<TContractInitialDataChangedEntry>;
};

export type TAdvContractEventRetry = TAdvContractEvent & {
    Data: string | undefined;
};

export type TAdvContractEventBinaryFile = TAdvContractEvent & {
    FileName: string;
    Data: string; // base64 encoded binary file
};

export type TAdvContractEventInputFieldNumber = TAdvContractEvent & {
    Value: number;
    Min: number;
    Max: number;
    Text: string;
    Hint: string;
    Suffix: string;
    Prefix: string;
};

export type TAdvContractEventInputDropdown = TAdvContractEvent & {
    ValuesKeys: Array<any>;
    ValuesText: Array<string>;
    CurValueKey: any;
    Text: string;
    Hint: string;
};

export type TAdvContractEventInputDate = TAdvContractEvent & {
    Value: string;
    ValueFormat: TAdvDatePickerValueFormat;
    Text: string;
    RangeType: TAdvDatePickerRangeType;
    DisplayFormat: TAdvDatePickerDisplayFormat;
};

export type TAdvContractEventInputTextField = TAdvContractEvent & {
    Value: string;
    MinLength: number;
    MaxLength: number;
    Caption: string;
};

export type TAdvContractEventInputDropdownAndTextField = TAdvContractEvent & {
    TextValue: string;
    TextMinLength: number;
    TextMaxLength: number;

    DropValuesKeys: Array<any>;
    DropValuesText: Array<string>;
    DropCurValueKey: any;

    TitleText: string;
};

type TContractEvData = {
    ev: TAdvContractEvent;
    key: string; // unique key of the event
};

const globalContractFiles = atom<{ data: string; fileName: string }[]>({
    key: "globalContractFiles",
    default: [],
});

const ContractFieldHost = ({
    bindingField,
    bindingProvider,
    fieldName,
    contractName,
    contractIndex,
}: {
    bindingField: string;
    bindingProvider: string;
    fieldName: string;
    curValue: any;
    contractName: string;
    contractIndex: number;
}) => {
    const binding = useMemo(() => {
        const res = deepCopy(AdvValueBindingDefaultValue);
        if (bindingProvider == "" || bindingField == "") return res;
        res.bindingTypeName = EAdvValueBinderType.BinderTypeDataProvider;
        res.bindingParams["provider"] = { value: bindingProvider, options: {} };
        res.bindingParams["providerField"] = { value: bindingField, options: {} };
        return res;
    }, [bindingField, bindingProvider]);

    const [provVal, , attributes] = useAdvValueBinderNoDataType<any>(
        binding,
        undefined,
        EAdvValueDataTypes.Any,
        contractIndex,
    );

    const { sendCallbackRequest } = useAdvSocketCallback();

    useAdvEffect(() => {
        if (bindingField != "" && attributes.isLoaded && attributes.fieldType != "")
            sendCallbackRequest<TAdvContractFieldDataValueChangedNtf, TSuccessClass>(
                "contract",
                "data_changed_ntf",
                {
                    ContractName: contractName,
                    GroupIndex: contractIndex,
                    Field: bindingField,
                    Prov: bindingProvider,
                    FieldName: fieldName,
                    Value: provVal,
                },
            )
                .then((success) => {
                    if (!success.Success) {
                        advcatch(
                            "Could not send change data notification to the server, the server rejected the change",
                        );
                    }
                })
                .catch((r) => advcatch("Could not notify about the data change", r));
    }, [
        attributes.fieldType,
        attributes.isLoaded,
        bindingField,
        bindingProvider,
        contractIndex,
        contractName,
        fieldName,
        provVal,
        sendCallbackRequest,
    ]);

    return <></>;
};

const ContractSingle = ({
    contractName,
    pageInfo,
}: {
    contractName: string;
    pageInfo: TPageInfo;
}) => {
    const { registerGlobalEventHandler, deRegisterGlobalEventHandler } = useAdvEvent();
    const { sendCallbackRequest } = useAdvSocketCallback();

    const [curEvs, setCurEvInternal] = useState<TContractEvData[]>([]);

    const { showSuccess } = useAdvToast();
    const { showError } = useAdvError();
    const { showInfo } = useAdvInfo();

    const variableID = useMemo(() => buildPageIDForVariableID(pageInfo), [pageInfo]);
    const contractID = useMemo(
        () => buildUniqueContractID(pageInfo, contractName),
        [contractName, pageInfo],
    );
    const [contractFields, setContractFields] = useAdvRecoilState(contractFieldsAtom(contractID));

    const setContractFiles = useAdvSetRecoilState(globalContractFiles);

    const addEv = useAdvCallback(
        (newEv: TContractEvData) => {
            setCurEvInternal((old) => {
                if (newEv.ev.DataType == EAdvContractEventType.Progress) {
                    const newEvAsProg = newEv.ev as TAdvContractEventProgress;
                    const oldEvIndex = old.findIndex((val) => {
                        if (val.ev.DataType == EAdvContractEventType.Progress) {
                            const oldEvAsProg = val.ev as TAdvContractEventProgress;
                            if (newEvAsProg.ID == oldEvAsProg.ID) return true;
                        }
                        return false;
                    });
                    // Wenn es ein 3/3 Fortschritt ist, wollen wir es nicht den Events hinzufügen
                    // + zusätzliche alle vorherigen Progress-Events entfernen sofern vorhanden
                    if (newEvAsProg.Progress == newEvAsProg.MaxProgress) {
                        if (oldEvIndex == -1) return old;
                        return old.filter((_, index) => index != oldEvIndex);
                    }
                    if (oldEvIndex != -1) {
                        const newAr = deepCopy(old);
                        const oldEvAsProg = newAr[oldEvIndex].ev as TAdvContractEventProgress;
                        oldEvAsProg.Progress = newEvAsProg.Progress;
                        oldEvAsProg.MaxProgress = newEvAsProg.MaxProgress;
                        oldEvAsProg.Text = newEvAsProg.Text;
                        return newAr;
                    }
                }
                return old.concat(newEv);
            });
        },
        [setCurEvInternal],
    );

    const remFirstEv = useAdvCallback(() => {
        setEventValue(() => {
            return { ofEventWithStateKey: "", value: "" };
        });
        setCurEvInternal((old) => {
            const newEvs = deepCopy(old);
            newEvs.shift();
            return newEvs;
        });
    }, [setCurEvInternal]);

    const toggleDPEditable = useAdvRecoilTransaction(dpClientSetIsEditableGlobalTrans, [
        dpClientSetIsEditableGlobalTrans,
    ]);
    const setDPEditDataTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, editData: Array<TEditDataEntry>) => {
                for (const editEntry of editData)
                    dpClientSetEditDatGlobalTrans(tb)(
                        pageInfo,
                        providerName,
                        editEntry.FieldName,
                        editEntry.Data,
                        editEntry.EditIndex,
                    );
            },
        [],
    );
    const setDPEditData = useAdvRecoilTransaction(setDPEditDataTrans, [setDPEditDataTrans]);
    const makeDPEditableAndSetData = useAdvRecoilTransaction(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, editData: Array<TEditDataEntry>) => {
                dpClientSetIsEditableGlobalTrans(tb)(pageInfo, providerName, true);
                setDPEditDataTrans(tb)(pageInfo, providerName, editData);
            },
        [setDPEditDataTrans],
    );
    const makeDPEditable = useAdvRecoilTransaction(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, editAble: boolean) => {
                dpClientSetIsEditableGlobalTrans(tb)(pageInfo, providerName, editAble);
            },
        [],
    );
    const removeContractActive = useAdvRecoilTransaction(
        (tb: TAdvTransactionInterface) => (contractID: TContractID) => {
            const activeContracts = tb.get(gActiveContracts);
            tb.set(
                gActiveContracts,
                activeContracts.filter(
                    (c) => JSON.stringify(c.contractID) != JSON.stringify(contractID),
                ),
            );
        },
        [],
    );
    const addContractActive = useAdvRecoilTransaction(
        (tb: TAdvTransactionInterface) => (contractID: TContractID) => {
            const activeContracts = tb.get(gActiveContracts);
            tb.set(gActiveContracts, activeContracts.concat({ contractID }));
        },
        [],
    );

    // Beachte: contract...<Event>-States werden u.U. durch ContractActions zurückgesetzt
    const [contractFulfilledEvent, setContractFulfilledEvent] = useAdvRecoilState(
        recoilContractEvent({ page: variableID, event: EContractEvent.Fulfilled }),
    );
    const [contractErrorEvent, setContractErrorEvent] = useAdvRecoilState(
        recoilContractEvent({ page: variableID, event: EContractEvent.Error }),
    );
    const [contractCancelEvent, setContractCancelEvent] = useAdvRecoilState(
        recoilContractEvent({ page: variableID, event: EContractEvent.Cancel }),
    );

    const queryStr = useMemo(
        () => buildQueryAsStr(buildPageParserParam(pageInfo.query, gDefaultIgnoreParamKeys)),
        [pageInfo.query],
    );

    const { reloadWebOrdersAsync } = useWebOrder();

    const lastDpKeys = useRef<Array<string>>([]);
    useAdvLayoutEffect(() => {
        registerGlobalEventHandler<TAdvContractEvent>(
            "contract",
            "event_" + contractName + queryStr,
            (data) => {
                addEv({ ev: data, key: nanoid() });
            },
        );
        registerGlobalEventHandler<TAdvContractEvent>(
            "contract",
            "start_" + contractName + queryStr,
            (data) => {
                addContractActive(contractID);
                const ev = data as TAdvContractEventStart;
                lastDpKeys.current = ev.ProviderList;
                for (const dpKey of lastDpKeys.current.filter((key) => key != "")) {
                    makeDPEditableAndSetData(
                        pageInfo,
                        dpKey,
                        ev.InitialData.map((val) => {
                            return {
                                FieldName: val.FieldName,
                                Data: val.FieldValue,
                                DataType: undefined,
                                EditIndex: ev.GroupIndex,
                            };
                        }),
                    );
                }
                setContractFields((old) => {
                    const res = deepCopy(old);

                    const newEvFields = ev.InitialFieldData.map((e) => {
                        return {
                            notifyOnInterfaceEntryValueChange: e.NotifyOnInterfaceEntryValueChange,
                            notifyProviderName: e.NotifyProviderName,
                            fieldName: e.Data.FieldName,
                            value: e.Data.FieldValue,
                        };
                    });
                    while (ev.GroupIndex >= res.length) {
                        res.push([]);
                    }
                    res[ev.GroupIndex] = newEvFields;
                    return res;
                });
                setContractFulfilledEvent({ contract: contractID, triggered: false });
                setContractErrorEvent({ contract: contractID, triggered: false });
                setContractCancelEvent({ contract: contractID, triggered: false });
                setCurEvInternal([]); // Eventuell noch vorhandene (alte) Events clearen
            },
        );
        registerGlobalEventHandler<TAdvContractEvent>(
            "contract",
            "fulfilled_" + contractName + queryStr,
            (data) => {
                removeContractActive(contractID);
                const ev = data as TAdvContractEventFulfilled;
                for (const dpKey of lastDpKeys.current.filter((key) => key != ""))
                    toggleDPEditable(pageInfo, dpKey, false);
                if (ev.Data !== undefined && ev.Data !== null && ev.Data.length > 0)
                    showSuccess(ev.Data);
                setContractFulfilledEvent({
                    contract: contractID,
                    triggered: true,
                    result: ev.Extra[0],
                });
                setCurEvInternal([]); // Events nicht mehr nötig wenn Contract fertig
                reloadWebOrdersAsync().catch(advcatch);
            },
        );
        registerGlobalEventHandler<TAdvContractEvent>(
            "contract",
            "cancelled_" + contractName + queryStr,
            (data) => {
                removeContractActive(contractID);
                const ev = data as TAdvContractEventCancelled;
                for (const dpKey of lastDpKeys.current.filter((key) => key != ""))
                    toggleDPEditable(pageInfo, dpKey, false);

                if (!ev.UserInitiatedCancel && !ev.AsFatalError && ev.Data != undefined) {
                    showInfo("Vorgang abgebrochen", ev.Data, undefined, "Contract cancel");
                } else if (!ev.UserInitiatedCancel || (!ev.AsFatalError && ev.Data == undefined)) {
                    showError(ev.Data ?? "Unknown error", undefined, "Contract cancel");
                    setContractErrorEvent({ contract: contractID, triggered: true });
                }
                setContractCancelEvent({
                    contract: contractID,
                    triggered: true,
                });
                setCurEvInternal([]); // Events nicht mehr nötig wenn Contract fertig

                for (const dpKey of lastDpKeys.current.filter((key) => key != ""))
                    makeDPEditable(pageInfo, dpKey, false);
            },
        );
        registerGlobalEventHandler<TAdvContractEvent>(
            "contract",
            "retry_" + contractName + queryStr,
            (data) => {
                const ev = data as TAdvContractEventRetry;
                showError(ev.Data ?? "unknown warning", undefined, "Contract retry");
            },
        );
        registerGlobalEventHandler<TAdvContractEvent>(
            "contract",
            "seteditdata_" + contractName + queryStr,
            (data) => {
                const ev = data as TAdvContractEventSetEditData;
                for (const dpKey of lastDpKeys.current.filter((key) => key != ""))
                    setDPEditData(pageInfo, dpKey, ev.EditData);
            },
        );
        registerGlobalEventHandler<TAdvContractEvent>(
            "contract",
            "setfielddata_" + contractName + queryStr,
            (data) => {
                const ev = data as TAdvContractEventFieldData;
                setContractFields((old) => {
                    const res = deepCopy(old);
                    if (ev.GroupIndex < res.length) {
                        const field = res[ev.GroupIndex].find((f) => f.fieldName == ev.FieldName);
                        if (field != undefined) {
                            field.value = ev.Data;
                        }
                    }
                    return res;
                });
            },
        );

        registerGlobalEventHandler<TAdvContractEvent>(
            "contract",
            "binary_file_" + contractName + queryStr,
            (data) => {
                const ev = data as TAdvContractEventBinaryFile;
                const fileData = Buffer.from(ev.Data, "base64");
                if (ev.FileName.endsWith(".pdf")) {
                    setContractFiles((old) => {
                        const res = deepCopy(old);
                        res.push({
                            data: ev.Data,
                            fileName: ev.FileName,
                        });
                        return res;
                    });
                } else {
                    // else directly download
                    fileDownload(fileData, ev.FileName);
                }
            },
        );

        return () => {
            deRegisterGlobalEventHandler("contract", "event_" + contractName);
            deRegisterGlobalEventHandler("contract", "start_" + contractName);
            deRegisterGlobalEventHandler("contract", "fulfilled_" + contractName);
            deRegisterGlobalEventHandler("contract", "cancelled_" + contractName);
            deRegisterGlobalEventHandler("contract", "retry_" + contractName);
            deRegisterGlobalEventHandler("contract", "seteditdata_" + contractName);
            deRegisterGlobalEventHandler("contract", "binary_file_" + contractName);
        };
    }, [
        contractName,
        deRegisterGlobalEventHandler,
        toggleDPEditable,
        pageInfo,
        registerGlobalEventHandler,
        addEv,
        showSuccess,
        showError,
        setDPEditData,
        makeDPEditableAndSetData,
        setContractFields,
        setContractFiles,
        setContractFulfilledEvent,
        contractID,
        setContractErrorEvent,
    ]);

    const [eventValue, setEventValue] = useState<{
        ofEventWithStateKey: string;
        value: any;
        value2?: any;
    }>({
        ofEventWithStateKey: "",
        value: undefined,
    });

    const events = useMemo(() => {
        if (
            contractFulfilledEvent.triggered ||
            contractErrorEvent.triggered ||
            contractCancelEvent.triggered
        )
            return <></>;
        if (curEvs.length > 0) {
            return (
                <>
                    {curEvs.map((curEv) => {
                        switch (curEv.ev.DataType) {
                            case EAdvContractEventType.InputData:
                                {
                                    alert("not implemented");
                                }
                                break;
                            case EAdvContractEventType.Question: {
                                const ev = curEv.ev as TAdvContractEventQuestion;
                                return (
                                    <AdvWeiterDialog
                                        key={curEv.key}
                                        hidden={false}
                                        onClosed={(weiter) => {
                                            sendCallbackRequest<
                                                TAdvContractEventCallback,
                                                TSuccessClass
                                            >("contract", "eventcb", {
                                                ContractName: contractName,
                                                GroupIndex: ev.GroupIndex,
                                                Args: [{ Name: "answer", Value: weiter }],
                                            })
                                                .then(() => {
                                                    remFirstEv();
                                                })
                                                .catch((r) =>
                                                    advcatch("Could not answer this event", r),
                                                );
                                        }}
                                        title={ev.Question}
                                        text={ev.Hint ?? ""}
                                        cancelText={ev.No}
                                        continueText={ev.Yes}
                                        ignoreTranslation //Kommen schon übersetzt vom Server
                                    ></AdvWeiterDialog>
                                );
                            }
                            case EAdvContractEventType.Progress: {
                                const ev = curEv.ev as TAdvContractEventProgress;
                                return (
                                    <Dialog key={curEv.key} hidden={false}>
                                        <ProgressIndicator
                                            label={
                                                "[" +
                                                ev.Progress.toString() +
                                                "/" +
                                                ev.MaxProgress.toString() +
                                                "]"
                                            }
                                            description={ev.Text}
                                            percentComplete={ev.Progress / ev.MaxProgress}
                                        />
                                    </Dialog>
                                );
                            }
                            case EAdvContractEventType.InitialDataChanged: {
                                const ev = curEv.ev as TAdvContractEventInitialDataChanged;
                                return (
                                    <AdvGenericDialog
                                        key={curEv.key}
                                        hidden={false}
                                        onClosed={(wasContinue) => {
                                            sendCallbackRequest<
                                                TAdvContractEventCallback,
                                                TSuccessClass
                                            >("contract", "eventcb", {
                                                ContractName: contractName,
                                                GroupIndex: ev.GroupIndex,
                                                Args: [{ Name: "answer", Value: wasContinue }],
                                            })
                                                .then(() => {
                                                    remFirstEv();
                                                })
                                                .catch((r) =>
                                                    advcatch("Could not answer this event", r),
                                                );
                                        }}
                                        title={ev.Question}
                                        text={""}
                                        cancelText={ev.No}
                                        continueText={ev.Yes}
                                        ignoreTranslation //Kommen schon übersetzt vom Server
                                    >
                                        <AdvStack>
                                            <AdvStackItem key={"ev-data-changed_info"}>
                                                <AdvText>
                                                    {LAN.LIST_OF_CHANGED_DATA_COLON.text}
                                                </AdvText>
                                            </AdvStackItem>
                                            {ev.ChangedData.map((val, index) => {
                                                return (
                                                    <AdvStackItem
                                                        key={"ev-data-changed" + index.toString()}
                                                    >
                                                        <AdvStack horizontal>
                                                            <AdvStackItem>
                                                                {val.FieldName + ": "}
                                                            </AdvStackItem>
                                                            <AdvStackItem>
                                                                {"[" +
                                                                    JSON.stringify(val.DataOld) +
                                                                    "]"}
                                                            </AdvStackItem>
                                                            <AdvStackItem>{" => "}</AdvStackItem>
                                                            <AdvStackItem>
                                                                {"[" +
                                                                    JSON.stringify(val.DataNew) +
                                                                    "]"}
                                                            </AdvStackItem>
                                                        </AdvStack>
                                                    </AdvStackItem>
                                                );
                                            })}
                                        </AdvStack>
                                    </AdvGenericDialog>
                                );
                            }
                            case EAdvContractEventType.InputFieldNumber: {
                                const ev = curEv.ev as TAdvContractEventInputFieldNumber;
                                setEventValue((old) => {
                                    if (old.ofEventWithStateKey != ev.StateKey)
                                        return {
                                            ofEventWithStateKey: ev.StateKey,
                                            value: ev.Value,
                                        };
                                    else return old;
                                });
                                return (
                                    <AdvGenericDialog
                                        key={curEv.key}
                                        hidden={false}
                                        onClosed={(weiter) => {
                                            if (
                                                eventValue.ofEventWithStateKey == curEv.ev.StateKey
                                            ) {
                                                sendCallbackRequest<
                                                    TAdvContractEventCallback,
                                                    TSuccessClass
                                                >("contract", "eventcb", {
                                                    ContractName: contractName,
                                                    GroupIndex: ev.GroupIndex,
                                                    Args: [
                                                        {
                                                            Name: "value",
                                                            Value: parseInt(eventValue.value),
                                                        },
                                                        { Name: "dialog", Value: weiter },
                                                    ],
                                                })
                                                    .then(() => {
                                                        remFirstEv();
                                                    })
                                                    .catch((r) =>
                                                        advcatch("Could not answer this event", r),
                                                    );
                                            }
                                        }}
                                        title={ev.Text}
                                        text={""}
                                        continueText={"OK"}
                                        ignoreTranslation //Kommen schon übersetzt vom Server
                                    >
                                        <AdvTextInput
                                            type="number"
                                            defaultValue={ev.Value.toString()}
                                            description={ev.Hint}
                                            min={ev.Min}
                                            max={ev.Max}
                                            onValueChanged={(newVal) => {
                                                if (newVal != undefined) {
                                                    setEventValue({
                                                        ofEventWithStateKey: ev.StateKey,
                                                        value: newVal,
                                                    });
                                                }
                                            }}
                                            suffix={ev.Suffix}
                                            prefix={ev.Prefix}
                                        ></AdvTextInput>
                                    </AdvGenericDialog>
                                );
                            }
                            case EAdvContractEventType.InputDropdown: {
                                const ev = curEv.ev as TAdvContractEventInputDropdown;
                                setEventValue((old) => {
                                    if (old.ofEventWithStateKey != ev.StateKey)
                                        return {
                                            ofEventWithStateKey: ev.StateKey,
                                            value: ev.CurValueKey,
                                        };
                                    else return old;
                                });
                                return (
                                    <AdvGenericDialog
                                        key={curEv.key}
                                        hidden={false}
                                        onClosed={(weiter) => {
                                            if (
                                                eventValue.ofEventWithStateKey == curEv.ev.StateKey
                                            ) {
                                                sendCallbackRequest<
                                                    TAdvContractEventCallback,
                                                    TSuccessClass
                                                >("contract", "eventcb", {
                                                    ContractName: contractName,
                                                    GroupIndex: ev.GroupIndex,
                                                    Args: [
                                                        { Name: "key", Value: eventValue.value },
                                                        { Name: "dialog", Value: weiter },
                                                    ],
                                                })
                                                    .then(() => {
                                                        remFirstEv();
                                                    })
                                                    .catch((r) =>
                                                        advcatch("Could not answer this event", r),
                                                    );
                                            }
                                        }}
                                        title={ev.Text}
                                        text={ev.Hint}
                                        continueText={"OK"}
                                        ignoreTranslation //Kommen schon übersetzt vom Server
                                    >
                                        <AdvDropdown
                                            value={ev.CurValueKey}
                                            options={ev.ValuesKeys.map((v, index) => {
                                                return {
                                                    key: v,
                                                    text: ev.ValuesText[index],
                                                    data: v,
                                                };
                                            })}
                                            translatableTextLabel={toAdvText("")}
                                            onValueChanged={(newVal) => {
                                                if (newVal != undefined) {
                                                    setEventValue({
                                                        ofEventWithStateKey: ev.StateKey,
                                                        value: newVal.key,
                                                    });
                                                }
                                            }}
                                        ></AdvDropdown>
                                    </AdvGenericDialog>
                                );
                            }
                            case EAdvContractEventType.InputDate: {
                                const ev = curEv.ev as TAdvContractEventInputDate;
                                setEventValue((old) => {
                                    if (old.ofEventWithStateKey != ev.StateKey)
                                        return {
                                            ofEventWithStateKey: ev.StateKey,
                                            value: ev.Value,
                                        };
                                    else return old;
                                });


                                return (
                                    <AdvGenericDialog
                                        key={curEv.key}
                                        hidden={false}
                                        onClosed={(weiter) => {
                                            if (
                                                eventValue.ofEventWithStateKey == curEv.ev.StateKey
                                            ) {
                                                sendCallbackRequest<
                                                    TAdvContractEventCallback,
                                                    TSuccessClass
                                                >("contract", "eventcb", {
                                                    ContractName: contractName,
                                                    GroupIndex: ev.GroupIndex,
                                                    Args: [
                                                        { Name: "key", Value: eventValue.value },
                                                        { Name: "dialog", Value: weiter },
                                                    ],
                                                })
                                                    .then(() => {
                                                        remFirstEv();
                                                    })
                                                    .catch((r) =>
                                                        advcatch("Could not answer this event", r),
                                                    );
                                            }
                                        }}
                                        title={ev.Text}
                                        text={""}
                                        continueText={"OK"}
                                        ignoreTranslation //Kommen schon übersetzt vom Server
                                    >
                                        <AdvDatePicker
                                            key={curEv.key}
                                            translatableTextLabel={toAdvText("")}
                                            rangeType={ev.RangeType}
                                            displayFormat={ev.DisplayFormat}
                                            valueFormat={ev.ValueFormat}
                                            value={ev.Value}
                                            onValueChanged={(newVal) => {
                                                if (newVal != undefined) {
                                                    setEventValue({
                                                        ofEventWithStateKey: ev.StateKey,
                                                        value: newVal,
                                                    });
                                                }
                                            }}
                                        ></AdvDatePicker>
                                    </AdvGenericDialog>
                                );
                            }

                            case EAdvContractEventType.InputTextField: {
                                const ev = curEv.ev as TAdvContractEventInputTextField;
                                setEventValue((old) => {
                                    if (old.ofEventWithStateKey != ev.StateKey)
                                        return {
                                            ofEventWithStateKey: ev.StateKey,
                                            value: ev.Value,
                                        };
                                    else return old;
                                });
                                return (
                                    <AdvGenericDialog
                                        key={curEv.key}
                                        hidden={false}
                                        onClosed={(weiter) => {
                                            if (
                                                eventValue.ofEventWithStateKey == curEv.ev.StateKey
                                            ) {
                                                sendCallbackRequest<
                                                    TAdvContractEventCallback,
                                                    TSuccessClass
                                                >("contract", "eventcb", {
                                                    ContractName: contractName,
                                                    GroupIndex: ev.GroupIndex,
                                                    Args: [
                                                        {
                                                            Name: "value",
                                                            Value: eventValue.value,
                                                        },
                                                        { Name: "dialog", Value: weiter },
                                                    ],
                                                })
                                                    .then(() => {
                                                        remFirstEv();
                                                    })
                                                    .catch((r) =>
                                                        advcatch("Could not answer this event", r),
                                                    );
                                            }
                                        }}
                                        title={ev.Caption}
                                        text={""}
                                        continueText={"OK"}
                                        canContinue={
                                            eventValue.value?.length >= ev.MinLength &&
                                            eventValue.value?.length <= ev.MaxLength
                                        }
                                        ignoreTranslation //Kommen schon übersetzt vom Server
                                    >
                                        <AdvTextInput
                                            type="text"
                                            minLength={ev.MinLength}
                                            maxLength={ev.MaxLength}
                                            onValueChanged={(newVal) => {
                                                if (newVal != undefined) {
                                                    setEventValue({
                                                        ofEventWithStateKey: ev.StateKey,
                                                        value: newVal,
                                                    });
                                                }
                                            }}
                                        ></AdvTextInput>
                                    </AdvGenericDialog>
                                );
                            }

                            case EAdvContractEventType.InputDropdownAndTextField: {
                                const ev = curEv.ev as TAdvContractEventInputDropdownAndTextField;
                                setEventValue((old) => {
                                    if (old.ofEventWithStateKey != ev.StateKey)
                                        return {
                                            ofEventWithStateKey: ev.StateKey,
                                            value: ev.TextValue,
                                        };
                                    else return old;
                                });
                                return (
                                    <AdvGenericDialog
                                        key={curEv.key}
                                        hidden={false}
                                        onClosed={(weiter) => {
                                            if (
                                                eventValue.ofEventWithStateKey == curEv.ev.StateKey
                                            ) {
                                                sendCallbackRequest<
                                                    TAdvContractEventCallback,
                                                    TSuccessClass
                                                >("contract", "eventcb", {
                                                    ContractName: contractName,
                                                    GroupIndex: ev.GroupIndex,
                                                    Args: [
                                                        {
                                                            Name: "key",
                                                            Value:
                                                                eventValue.value != undefined &&
                                                                eventValue.value != ""
                                                                    ? eventValue.value
                                                                    : ev.DropCurValueKey,
                                                        },
                                                        {
                                                            Name: "value",
                                                            Value:
                                                                eventValue.value2 != undefined
                                                                    ? eventValue.value2
                                                                    : "",
                                                        },
                                                        { Name: "dialog", Value: weiter },
                                                    ],
                                                })
                                                    .then(() => {
                                                        remFirstEv();
                                                    })
                                                    .catch((r) =>
                                                        advcatch("Could not answer this event", r),
                                                    );
                                            }
                                        }}
                                        title={ev.TitleText}
                                        text={""}
                                        continueText={"OK"}
                                        ignoreTranslation //Kommen schon übersetzt vom Server
                                    >
                                        <AdvDropdown
                                            value={ev.DropCurValueKey}
                                            options={ev.DropValuesKeys.map((v, index) => {
                                                return {
                                                    key: v,
                                                    text: ev.DropValuesText[index],
                                                    data: v,
                                                };
                                            })}
                                            translatableTextLabel={toAdvText("")}
                                            onValueChanged={(newVal) => {
                                                if (newVal != undefined) {
                                                    setEventValue((old) => {
                                                        return {
                                                            ...old,
                                                            ofEventWithStateKey: ev.StateKey,
                                                            value: newVal.key,
                                                        };
                                                    });
                                                }
                                            }}
                                        ></AdvDropdown>
                                        <AdvTextInput
                                            type="text"
                                            minLength={ev.TextMinLength}
                                            maxLength={ev.TextMaxLength}
                                            onValueChanged={(newVal) => {
                                                if (newVal != undefined) {
                                                    setEventValue((old) => {
                                                        return {
                                                            ...old,
                                                            ofEventWithStateKey: ev.StateKey,
                                                            value2: newVal,
                                                        };
                                                    });
                                                }
                                            }}
                                            multiline={true}
                                            styles={{ root: { paddingTop: "8px" } }}
                                        ></AdvTextInput>
                                    </AdvGenericDialog>
                                );
                            }
                            default:
                                advlog("contract event not implemented.");
                                break;
                        }
                        return <></>;
                    })}
                </>
            );
        }
        return <></>;
    }, [
        contractErrorEvent.triggered,
        contractCancelEvent.triggered,
        contractFulfilledEvent.triggered,
        contractName,
        curEvs,
        eventValue.ofEventWithStateKey,
        eventValue.value,
        eventValue.value2,
        remFirstEv,
        sendCallbackRequest,
    ]);

    const contractFieldElements = useMemo(() => {
        return contractFields.map((c, index) => (
            <React.Fragment key={"contract_field_host_outer_" + index.toString()}>
                {c.map((v, vIndex) => {
                    return (
                        <ContractFieldHost
                            key={"contract_field_host_" + vIndex.toString()}
                            bindingField={v.notifyOnInterfaceEntryValueChange}
                            bindingProvider={v.notifyProviderName}
                            fieldName={v.fieldName}
                            curValue={undefined}
                            contractName={contractName}
                            contractIndex={index}
                        ></ContractFieldHost>
                    );
                })}
            </React.Fragment>
        ));
    }, [contractFields, contractName]);
    return (
        <>
            {events}
            {contractFieldElements}
        </>
    );
};

const ContractEvents = () => {
    const instance = useContext(pageInstanceContext);
    const { disableQueue } = useAdvEvent();
    const loadedPageID = useAdvRecoilValue(dynamicPageAtomName(instance.index));
    const router = useAdvRouter();
    useAdvEffect(() => {
        const pageID = buildUniquePageID(router.pageInfo);
        if (loadedPageID == pageID) {
            // TODO: 157870 Erstmal ausgebaut, falls es keine Probleme gemacht hat
            // bei Finaler-Umsetzung bitte entfernen
            //  disableQueue(pageID);
        }
    }, [loadedPageID, router.pageInfo]);

    return <></>;
};

const ContractsHostComp = () => {
    useAdvComponent(ContractsHostComp);

    const contracts = useAdvRecoilValue(gContracts);

    const contractFiles = useAdvRecoilValue(globalContractFiles);
    const setContractFiles = useAdvSetRecoilState(globalContractFiles);

    return (
        <>
            {contracts.map((val) => {
                return (
                    <ContractSingle
                        key={`contracthost${val.contractID.id}`}
                        contractName={val.contractName}
                        pageInfo={val.pageInfo}
                    ></ContractSingle>
                );
            })}
            {contractFiles.map((f, fIndex) => {
                return (
                    <AdvModal
                        isOpen={true}
                        headline={f.fileName}
                        ignoreTranslation
                        onDismiss={() => {
                            setContractFiles((old) => {
                                const res = deepCopy(old);
                                res.splice(fIndex, 1);
                                return res;
                            });
                        }}
                        key={"globalContractFile" + fIndex.toString()}
                        styles={{ root: { main: { minWidth: "90%", minHeight: "90%" } } }}
                    >
                        <AdvStack grow verticalFill>
                            <object
                                data={"data:application/pdf;base64," + f.data}
                                type={"application/pdf"}
                                style={{ width: "100%", height: "100%" }}
                            ></object>
                        </AdvStack>
                    </AdvModal>
                );
            })}
            <ContractEvents></ContractEvents>
        </>
    );
};

const ContractsHost = React.memo(ContractsHostComp, deepCompareJSXProps);
export default ContractsHost;
