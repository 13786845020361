// helper string to easily find text that is not part of this list
const tHelp = "";
// prettier-ignore
export const L4N_INTERNAL = { // L4N damit es nicht beim tippen vorgeschlagen wird
    EVENTS: { text: tHelp + "Events" },
    EVENTS_SHOW: { text: tHelp + "OnShow" },
    EVENTS_SHOW_DESCR: { text: tHelp + "(SERVER) Aktion wird beim Abrufen der Page ausgeführt. Unterstützt nur Page-Parameter." },
    EVENTS_HIDE: { text: tHelp + "OnHide" },
    EVENTS_HIDE_DESCR: { text: tHelp + "(SERVER) Aktion wird ausgeführt, wenn eine andere Page aufgerufen wird." },
    EVENTS_INIT: { text: tHelp + "OnInit" },
    EVENTS_INIT_DESCR: { text: tHelp + "(SERVER) Aktion wird ausgeführt, wenn die Page initialisiert, also in den Cache geladen wird. Unterstützt nur Page-Parameter." },
    EVENTS_DESTROY: { text: tHelp + "OnDestroy" },
    EVENTS_DESTROY_DESCR: { text: tHelp + "(SERVER) Aktion wird ausgeführt, sobald die Page dauerhaft geschloßen wird, also aus dem Cache entfernt wird. Keine Parameter sind hierbei erlaubt." },
    EVENTS_CHANGED: { text: tHelp + "OnChanged" },
    EVENTS_CHANGED_DESCR: { text: tHelp + "Aktion wird ausgeführt, wenn sich der Wert dieser Komponente geändert hat." },
    EVENTS_BEFORE_CHANGED: { text: tHelp + "OnBeforeChanged" },
    EVENTS_BEFORE_CHANGED_DESCR: { text: tHelp + "Aktion wird ausgeführt, bevor sich der Wert dieser Komponente geändert hat." },
    EVENTS_CONTRACT_FULFILLED: { text: tHelp + "OnContractFulfilled" },
    EVENTS_CONTRACT_FULFILLED_DESCR: { text: tHelp + "Aktion wird ausgeführt, nachdem ein Auftrag fulfilled wurde." },
    EVENTS_CONTRACT_ERROR: { text: tHelp + "OnContractError" },
    EVENTS_CONTRACT_ERROR_DESCR: { text: tHelp + "Aktion wird ausgeführt, nachdem ein Auftrag einen Fehler hatte." },
    EVENTS_CONTRACT_CANCEL: { text: tHelp + "OnContractCancel" },
    EVENTS_CONTRACT_CANCEL_DESCR: { text: tHelp + "Aktion wird ausgeführt, nachdem ein Auftrag abgebrochen wurde." },

    LOAD_ALL: { text: tHelp + "Lade alle Daten" },
    LOAD_AND_SELECT_ALL: { text: tHelp + "Lade und selektiere alle Daten" },
    DONT_LOAD_OR_SELECT_ALL: { text: tHelp + "Nichts vorladen oder selektieren" },
    IS_LIVE: { text: tHelp + "Live-Daten (ohne Temp-Table)" },

    SELECT: { text: tHelp + "Auswählen" },
    PLEASE_SELECT: { text: tHelp + "Eine Option wählen" },
    APPEND: { text: tHelp + "Anhängen" },
    APPEND_NOW: { text: tHelp + "Jetzt anhängen" },

    GROUP: { text: tHelp + "Gruppierung" },
    GROUP_NAME: { text: tHelp + "Gruppen-Name" },
    GROUP_FIELD: { text: tHelp + "Gruppen-Feld" },
    GROUP_PLACEHOLDER: { text: tHelp + "Platzhalter" },
    GROUP_DISPLAYTEXT: { text: tHelp + "Anzeigetext" },

    DATAPROVIDER: { text: tHelp + "Datenanbieter" },
    DATAPROVIDER_NAME: { text: tHelp + "Datenanbieter-Name" },
    DATAPROVIDER_FIELD_NAME: { text: tHelp + "Datenanbieter-Feld-Name" },
    COMPARE_DATAPROVIDER_FIELD_NAME: { text: tHelp + "Datenanbieter-Vergleichsfeld-Name" },
    DATASOURCE: { text: tHelp + "Datenquelle" },
    ALWAYS_SHOW_DROPDOWN_AREAS: { text: tHelp + "Drop-Areas" },
    DESIGNER_MODUS: { text: tHelp + "Designer-Modus" },
    PREVIEW: { text: tHelp + "Vorschau" },
    COMPONENTS: { text: tHelp + "Komponenten" },

    DATAPROVIDER_IS_LIVE: { text: tHelp + "Benutze Live-Daten" },

    CONTRACTS: { text: tHelp + "Aufträge" },
    CONTRACT_PROVIDER_NAME: { text: tHelp + "Auftrags-Provider-Name" },
    CONTRACT_NAME: { text: tHelp + "Auftrags-Name" },
    CONTRACT_FUNC_NAME: { text: tHelp + "Auftrags-Funktions-Name" },
    CONTRACT: { text: tHelp + "Auftrag" },

    DYNAMIC_PAGE: { text: tHelp + "Dynamic Page" },
    ENDLESS_TABLE: { text: tHelp + "EndlessTable" },
    LABEL: { text: tHelp + "Label" },
    PROGRESSBAR_MODE: { text: tHelp + "Modus" },
    PROGRESSBAR_MODE_SINGLE: { text: tHelp + "Single" },
    PROGRESSBAR_MODE_MULTI: { text: tHelp + "Multi" },
    PROGRESSBAR_MODE_DESCR: { text: tHelp + "Ermöglicht die Darstellung mehrerer Daten" },
    PROGRESSBAR_DATA: { text: tHelp + "Daten" },
    PROGRESSBAR_DATA_DESCR: { text: tHelp + "Daten" },
    PROGRESSBAR_DATA_VALUE: { text: tHelp + "Wert (numerisch)" },
    PROGRESSBAR_DATA_LABEL: { text: tHelp + "Label" },
    PROGRESSBAR_DATA_COLOR: { text: tHelp + "Farbe" },
    PROGRESSBAR_DATA_BAR: { text: tHelp + "Balken anzeigen" },
    PROGRESSBAR_DATA_LEGEND: { text: tHelp + "Legende anzeigen" },
    TEXTINPUT_DESCRIPTION: { text: tHelp + "Beschreibung" },
    PLACEHOLDER: { text: tHelp + "Platzhalter" },
    COLOR: { text: tHelp + "Farbe" },
    RESOURCE_FILE: { text: tHelp + "Resourcen-Datei" },
    RESOURCE_HEIGHT: { text: tHelp + "Resourcen-Höhe" },
    LABELS: { text: tHelp + "Beschriftung" },
    DATASETS: { text: tHelp + "Datenmenge" },
    CHART_LABELS: { text: tHelp + "Achsen-Beschriftungen" },
    CHART_DATASETS: { text: tHelp + "Datenmengen" },
    CHART_CURRENT_VALUE: { text: tHelp + "Aktueller Wert" },
    CHART_MAX_VALUE: { text: tHelp + "Maximal-Datenwert" },
    ORDER_ARTICLE_LIST: { text: tHelp + "Artikel-Liste (nur bindings)" },
    ORDER_DATE_LIST: { text: tHelp + "Mögliche Datumwerte (nur bindings)" },
    ORDER_DATE_SELECTED: { text: tHelp + "Ausgewähltes Datum (binding)" },
    ORDER_DATE_PRESELECTED: { text: tHelp + "Vorausgewähltes Datum (binding)" },
    AUSSTA_LIST_READER: { text: tHelp + "Variable von der die aktuelle Ausstattung gelesen werden soll" },
    AUSSTA_LIST_WRITER: { text: tHelp + "Variable in der die aktuelle Ausstattung geschrieben werden soll" },
    KDARTI_ID_READER: { text: tHelp + "Variable von der die aktuellen KdArti-IDs gelesen werden soll" },
    KDARTI_BEZ_READER: { text: tHelp + "Variable von der die aktuellen KdArti-Bezs gelesen werden soll" },
    ART_GROE_READER: { text: tHelp + "Variable von der die aktuellen ArtGroes pro KdArti gelesen werden soll" },
    DATASET_DATA: { text: tHelp + "Zahlenwert:" },
    ADD_DATASET_DATA: { text: tHelp + "Neuen Zahlenwert hinzufügen" },
    CHART: { text: tHelp + "Diagramm" },
    ICON: { text: tHelp + "Icon" },
    TEXT: { text: tHelp + "Text" },
    BUTTON: { text: tHelp + "Button" },
    CHECKBOX: { text: tHelp + "Checkbox" },
    PROGRESSBAR: { text: tHelp + "Fortschrittsbalken" },
    COMBOBOX: { text: tHelp + "Combobox" },
    CALENDAR: { text: tHelp + "Kalender" },
    CALENDAR_ORDERS: { text: tHelp + "Kalender (Bestellungen)" },
    PROVIDER_ORDERS: { text: tHelp + "Provider-Daten (Bestellungen)" },
    CONTROL_ORDERS: { text: tHelp + "Kontrolle (Bestellungen)" },
    ENDLESS_TABLE_ARTICLES: { text: tHelp + "Bestellungen: Artikel" },
    ENDLESS_TABLE_VSA: { text: tHelp + "Bestellungen: VSAs" },
    ENDLESS_TABLE_ANF: { text: tHelp + "Bestellungen: Anforderungen" },
    ARTI_LIST: { text: tHelp + "Trägerartikel (TraeArti)" },
    CONTRACT_ACTIONS_COMP: { text: tHelp + "Contract-Actions" },
    ANPROBE_ART_LIST: { text: tHelp + "Träger-Anprobe Artikel" },
    TRAEARTI_ANLAGE_LIST: { text: tHelp + "Trägerartikel Anlage" },
    AFTER_BENCHMARK_ACTION: { text: tHelp + "Nach Benchmark-Ausführungs Webaktion" },
    AFTER_BENCHMARK_TIMEOUT: { text: tHelp + "Nach Benchmark-Ausführungs Webaktion-Delay in ms" },
    BENCHMARK: { text: tHelp + "Benchmark" },
    COMBOBOX_ITEMS: { text: tHelp + "Combobox-Items" },
    DROPDOWN: { text: tHelp + "Dropdown" },
    RESOURCE: { text: tHelp + "Resource" },
    ADD_RESOURCES: { text: tHelp + "Resourcen hinzufügen" },
    ADD_RESOURCES_DBSYSTEM: { text: tHelp + "Resourcen hinzufügen (nicht Mandant)" },
    RESOURCES: { text: tHelp + "Resourcen" },
    RESOURCES_DBSYSTEM: { text: tHelp + "DB-System Resourcen" },
    DROPDOWN_TEXT_ITEMS: { text: tHelp + "Dropdown-Text-Items" },
    DROPDOWN_DATA_ITEMS: { text: tHelp + "Dropdown-Data-Items" },
    COMBOBOX_ALLOW_FREEFORM: { text: tHelp + "Beliebigen Text erlauben" },
    MULTI_SELECT: { text: tHelp + "Mehrauswahl" },
    RADIOBUTTONS: { text: tHelp + "Radiobuttons" },
    TEXT_INPUT: { text: tHelp + "Text-Input" },
    DATEPICKER: { text: tHelp + "Datums-Auswahl" },
    COPY_CAT: { text: tHelp + "Nachahmer" },
    GRID: { text: tHelp + "Grid" },
    GRID_ITEM: { text: tHelp + "Layout (Grid)" },
    GROUPBOX: { text: tHelp + "Groupbox" },
    STACK: { text: tHelp + "Stack" },
    STACK_ITEM: { text: tHelp + "Layout (Stack)" },
    TAB_CONTROL: { text: tHelp + "Tab Control" },
    TAB_ITEM: { text: tHelp + "Tab Item" },
    REACT_GRID: { text: tHelp + "ReactGrid" },
    WRITE_DATE_PRETTY: { text: tHelp + "Ausgewähltes Datum (schreibend, formatiert)" },
    READ_ONLY: { text: tHelp + "Schreibgeschützt" },

    WITH_STACK: { text: tHelp + "Mit Stack" },
    STANDARD_LAYOUT: { text: tHelp + "Standard Layout" },
    STANDARD_GRID_LAYOUT: { text: tHelp + "Standard Grid-Layout" },
    ONE_TWO_ONE_GRID_LAYOUT: { text: tHelp + "1-2-1 Grid-Layout" },
    ONE_TWO_ONE_LAYOUT: { text: tHelp + "1-2-1 Layout" },
    HORIZONTAL_PRESET_WITH_CHILDREN: { text: tHelp + "Horizontal Preset with Children" },
    NON_HORIZONTAL_PRESET: { text: tHelp + "Not-Horizontal Preset" },
    TEXT_FIELD_PRESET: { text: tHelp + "Field Preset" },
    DEFAULT_PRESET: { text: tHelp + "Default" },
    PRESET: { text: tHelp + "Vorlage" },
    PAGE_VARIABLES: { text: tHelp + "Page-Variablen" },
    PAGE_VARIABLE: { text: tHelp + "Page-Variable" },
    PAGE_VARIABLE_ITERATABLE: { text: tHelp + "Page-Variable {}" },
    PASSED_VARIABLE: { text: tHelp + "Logik übergebene Variable" },
    DISPLAY_MODE: { text: tHelp + "Display mode" },
    SAVED_PAGE: { text: tHelp + "Seite \"{}\" gespeichert!" },
    PAGE_VAR_TITLE: { text: tHelp + "Variablen, die von Komponenten der Page benutzt werden können" },
    DESIGNER_MODE_ALL: { text: tHelp + "Alle" },
    DESIGNER_MODE_LOCAL: { text: tHelp + "Nur lokale" },
    DESIGNER_MODE_GLOBAL: { text: tHelp + "Nur globale" },
    DESIGNER_ACTIONS_MODAL: { text: tHelp + "Aktionen für \"{}\"" },
    DESIGNER_DATASOURCES_MODAL: { text: tHelp + "Datenquellen für \"{}\"" },
    NAME: { text: tHelp + "Name" },
    GENERAL_NAME: { text: tHelp + "Genereller Name" },
    NAME_TITLE: { text: tHelp + "Bezeichnung" },
    NAME_SUBTITLE: { text: tHelp + "Sekundär-Bezeichnung" },
    PROVIDER: { text: tHelp + "Provider" },
    DO_FILTER: { text: tHelp + "Filtern" },
    SORT: { text: tHelp + "Sortieren" },
    COLUMNS: { text: tHelp + "Spalten" },
    ROWS: { text: tHelp + "Zeilen" },
    COLUMN: { text: tHelp + "Spalte" },
    ROW: { text: tHelp + "Zeile" },
    AS_FLEX_CONTAINER: { text: tHelp + "Als Flex-Container" },
    CONTENT_AS_FLEX_CONTAINER: { text: tHelp + "Inhalt als Flex-Container" },
    SELECTION_MODE: { text: tHelp + "Selektier-Modus" },
    AUTO_GROUPING: { text: tHelp + "Auto-Gruppierung" },
    DATA_POSITIONING: { text: tHelp + "Datensatz-Positionierung" },
    TABLE_HEADER: { text: tHelp + "Tabellen-Aktionen" },
    TABLE_FILTERS: { text: tHelp + "Tabellen-Filter" },
    TABLE_ADD_FILTER: { text: tHelp + "Tabellen-Filter hinzufügen" },
    TABLE_DEFAULT_ACTION: { text: tHelp + "Standard-Aktion" },
    PAGE_IS_REPORT: { text: tHelp + "Auswertungsseite"},
    PAGE_IS_REPORT_DESCR: { text: tHelp + "Gibt an, ob diese Seite eine Auswertung enthält."},
    NEVER_CACHE: { text: tHelp + "Seite nicht cachen" },
    NEVER_CACHE_DESCR: { text: tHelp + "Der Server verwendet nie den Seiten-Cache, wenn die Seite geöffnet wird" },
    TITLE: { text: tHelp + "Titel" },
    SECONDARY_TITLE: { text: tHelp + "Sekundär-Titel" },
    CATEGORY: { text: tHelp + "Kategorie" },
    MENU_ICON: { text: tHelp + "Menü-Icon" },
    NAV_ACTION: { text: tHelp + "Navigations-Webaktion" },
    TRANSLATION_CONTEXT: { text: tHelp + "Übersetzungskontext" },
    ALLOW_MULTILINE: { text: tHelp + "Mehrzeilig" },
    AUTO_ADJUST_HEIGHT: { text: tHelp + "Höhe automatisch anpassen" },
    FORM_REQUIRED: { text: tHelp + "Pflichtfeld" },
    IGNORE_TRANSLATION: { text: tHelp + "Nicht übersetzen" },
    IGNORE_TRANSLATION_PLACEHOLDER: { text: tHelp + "Nicht übersetzen (Platzhalter)" },
    IGNORE_CACHE: { text: tHelp + "Nicht cachen" },
    CAN_COLLAPSE: { text: tHelp + "Kann schließen" },
    COLLAPSE_DIR: { text: tHelp + "Schließrichtung" },
    ON_CLICK_ACTION: { text: tHelp + "On-Click Aktion" },
    NORMAL_FONT_WEIGHT: { text: tHelp + "Normale Text-Dicke" },
    DUPLICATION_AMOUNT: { text: tHelp + "Dublizierungsmenge" },
    VARIANT: { text: tHelp + "Variante" },
    PRIMARY: { text: tHelp + "Primär" },
    AUTO_GROW: { text: tHelp + "Automatisch wachsen" },
    HOTKEY: { text: tHelp + "Hotkey" },
    CHECKED: { text: tHelp + "Aktiviert" },
    DISABLED: { text: tHelp + "Nur lesbar" },
    SUBMIT_ACTION: { text: tHelp + "Submit-Aktion" },
    HIDE_WHEN_EMPTY: { text: tHelp + "Verstecken wenn keine Daten" },
    HIDE_WHEN_BELOW_RECORDS: { text: tHelp + "Verstecken wenn weniger als X Daten" },
    DISABLE_WHEN_EMPTY: { text: tHelp + "Nur lesbar wenn keine Daten" },
    SIMPLIFIED_DESIGN: { text: tHelp + "Vereinfachtes Design" },
    VALUE: { text: tHelp + "Wert" },
    DATEPICKER_VALUE_FORMAT: { text: tHelp + "Formatierung (Value)" },
    DATEPICKER_DISPLAY_FORMAT: { text: tHelp + "Formatierung (Anzeige)" },
    DATEPICKER_RANGE_TYPE: { text: tHelp + "Auswahlmodus" },
    DATEPICKER_VALUE_TODAY: { text: tHelp + "Standard: Heute" },
    DATEPICKER_MONTHPICK_VISIBLE: { text: tHelp + "Monate anzeigen" },
    INPUT_TYPE: { text: tHelp + "Datentyp des Inputs" },
    VERTICAL_FILL: { text: tHelp + "Fülle vertikal" },
    HORIZONTAL_FILL: { text: tHelp + "Fülle horizontal" },
    GRID_ALIGN_ITEMS: { text: tHelp + "Ausrichtung Items" },
    GRID_ROW_GAP: { text: tHelp + "Zeilenabstand" },
    GRID_COL_GAP: { text: tHelp + "Spaltenabstand" },
    DIRECTION: { text: tHelp + "Richtung" },
    HORIZONTAL: { text: tHelp + "Horizontal" },
    VERTICAL: { text: tHelp + "Vertikal" },
    STACKED: { text: tHelp + "Gestapelt" },
    SHOW_LEGEND: { text: tHelp + "Zeige Legende" },
    SHOW_X_ACHSIS: { text: tHelp + "Zeige X-Achse" },
    SHOW_Y_ACHSIS: { text: tHelp + "Zeige Y-Achse" },
    SHOW_VALUES_AS_EXTRA_Y_ACHSIS: { text: tHelp + "Werte als extra Y-Achse" },
    CHART_TYPE: { text: tHelp + "Diagramm-Typ" },
    CHART_SHOWSELECTEDROWSONLY: { text: tHelp + "Zeige nur ausgewählte Datensätze" },
    CHART_RANDOMIZED_COLORS: { text: tHelp + "Zufällige Farbwerte für die Wertemenge nutzen" },
    HEADING: { text: tHelp + "Überschrift" },
    ADDITIONAL_HEADING: { text: tHelp + "Zusatzinfos in der Überschrift" },
    OPENED: { text: tHelp + "Geöffnet" },
    COLUMN_COUNT: { text: tHelp + "Spaltenanzahl" },
    ROWHEIGHT: { text: tHelp + "Zeilenhöhe" },
    LAYOUT: { text: tHelp + "Layout" },
    ORIENTATION: { text: tHelp + "Ausrichtung" },
    WRAP: { text: tHelp + "Wrap" },
    ALIGN_HORIZONTAL: { text: tHelp + "horizontale Ausrichtung" },
    ALIGN_VERTICAL: { text: tHelp + "vertikale Ausrichtung" },
    CHILDREN_GAP: { text: tHelp + "Abstand der Kinder-Elemente" },
    GROW_RATIO: { text: tHelp + "Wachs-Verhältnis" },
    SHRINK_RATIO: { text: tHelp + "Schrumpf-Verhältnis" },
    ALIGNMENT: { text: tHelp + "Ausrichtung" },
    HIDDEN: { text: tHelp + "Versteckt" },
    FLEX_BASIS: { text: tHelp + "Initial-Platzverbrauch" },
    MIN_WIDTH: { text: tHelp + "Minimale Breite" },
    MAX_WIDTH: { text: tHelp + "Maximale Breite" },
    MIN_HEIGHT: { text: tHelp + "Minimale Höhe" },
    MAX_HEIGHT: { text: tHelp + "Maximale Höhe" },
    THEME_SWIZZLE: { text: tHelp + "Farbzuordnungen" },
    FONT_OVERWRITE: { text: tHelp + "Font-Anpassungen" },
    FONT_SIZE: { text: tHelp + "Font-Größe" },
    FONT_WEIGHT: { text: tHelp + "Font-Dicke" },
    FONT_FAMILY: { text: tHelp + "Schriftart" },

    HELPER_FUNCTIONS: { text: tHelp + "Hilfs-Funktionen" },
    GRID_BUILDER: { text: tHelp + "Grid-Designer" },
    MIN_MAX_WIDTH: { text: tHelp + "Min-,Max-Breite" },
    MIN_MAX_HEIGHT: { text: tHelp + "Min-,Max-Höhe" },
    CUSTOM: { text: tHelp + "Individuell" },

    INPUT: { text: tHelp + "Eingabe" },
    PRESENTATION: { text: tHelp + "Darstellung" },

    DATASOURCE_FILTER_MAPPING: { text: tHelp + "Datenquellen-Filterung" },
    SORTING_FILTER: { text: tHelp + "Standard Datensatzsortierung & -filterung" },
    PARAMETER: { text: tHelp + "Parameter" },
    APPLY_VALUE_TO_FILTER: { text: tHelp + "Filter auf Wert anwenden" },
    ADD_FILTER_PARAM: { text: tHelp + "Füge Filter-Parameter hinzu" },
    DATAPROVIDER_FILTER_TITLE: { text: tHelp + "Gibt an wie die Datenquellen-Filter auf den serverseitigen Dataprovider angewendet werden." },
    DATAPROVIDER_CLIENT_FILTER_TITLE: { text: tHelp + "Die Standard-Sortierung und Filterung, die angewendet wird, sobald der Dataprovider initialisiert wird." },

    SAVE_NOT_POSSIBLE_DATA_MISSING: { text: tHelp + "Speichern nicht möglich, da Daten fehlen!" },

    SEARCH_TEXT: { text: tHelp + "Suchtext" },
    SORT_FIELD: { text: tHelp + "Sortierungs-Feld" },
    ASC_SORT: { text: tHelp + "Aufsteigende Sortierung" },
    DESC_SORT: { text: tHelp + "Absteigende Sortierung" },
    CONST_SORT: { text: tHelp + "Sortierung ist nicht änderbar" },

    WEBACTION_NO_PARAMS: { text: tHelp + "Webaktion das nur Page-Variablen als Argumente erlaubt" },
    WEBACTION_PAGE_CHOOSE: { text: tHelp + "Webaktion-Seite auswählen" },
    WEBACTION_CATEGORY_CHOOSE: { text: tHelp + "Webaktion-Kategorie auswählen" },

    BINDING_TYPE: { text: tHelp + "Binding-Typ" },
    BINDING_NAME: { text: tHelp + "Binding-Name" },
    BINDING_PARAM: { text: tHelp + "Binding-Parameter 1" },
    BINDING_LOGIC: { text: tHelp + "Binding-Logik" },
    BINDING_LOGIC_VALUE: { text: tHelp + "Binding-Logik-Value (als Text)" },

    BINDING_DATAPROVIDER_TO_BIND: { text: tHelp + "Datenanbieter, der gebunden wird" },
    BINDING_DATAPROVIDER_FIELD_TO_BIND: { text: tHelp + "Datenanbieter-Feld, das gebunden wird" },
    BINDING_PAGE_VAR_TO_BIND: { text: tHelp + "Page-Variablen-Name" },
    BINDING_CONTRACT_TO_BIND: { text: tHelp + "Auftrag, der gebunden wird" },
    BINDING_CONTRACT_FIELD_TO_BIND: { text: tHelp + "Auftrags-Feld, das gebunden wird" },

    NO_SELECTION: { text: tHelp + "Keine Auswahl" },
    CURRENT_SELECTION: { text: tHelp + "Aktuelle Auswahl" },

    ALL_CATEGORIES: { text: tHelp + "Alle Kategorien" },

    PAGE_RENAME_INFO: { text: tHelp + "Info: Da Datenquellen und andere Objekte zu dieser Page gehören können, kann das Umbenennen etwas länger dauern." },

    PAGE_INLINE: { text: tHelp + "Seite"},

    INPUT_A_NAME: { text: tHelp + "Namen eingeben" },
    INSERT_NEW_NAME: { text: tHelp + "Neuen Namen eingeben" },
    CREATE_FILE: { text: tHelp + "{} erstellen" },
    RENAME_FILE: { text: tHelp + "{} umbenennen" },
    NO_FILE_SELECTED: { text: tHelp + "Kein(e) {} ausgewählt" },

    ADD_PAGE_VARIABLE: { text: tHelp + "Füge Page-Variable hinzu" },
    PAGE_VARIABLE_NAME: { text: tHelp + "Page-Variable-Name" },
    PAGE_DEFAULT_VALUE: { text: tHelp + "Default-Wert" },
    REQUIRED: { text: tHelp + "Notwending" },
    REQUIRED_INFO_TEXT: { text: tHelp + "Notwendinge Page-Variablen sind Variablen die schon bei dem Aufruf der Page existieren und initialisiert worden sein müssen." },
    CONST: { text: tHelp + "Konstant" },
    CONST_PAGE_INFO_TEXT: { text: tHelp + "Konstante Page-Variablen sind sinnvoll, wenn ein Dataprovider mit dem Wert initialisiert werden sollen, oder wenn ein Input-Element nicht auf die Variable schreiben können soll." },
    COULD_NOT_FIND_REQUIRED_PAGE_VAR: { text: tHelp + "Could not find required page variable \"{}\"" },

    WEBACTION_PARAMS: { text: tHelp + "Webaktion-Parameter-Mapping" },
    WEBACTION_ADD_PARAM: { text: tHelp + "Füge Webaktion-Parameter hinzu" },
    WEBACTION_PARAM: { text: tHelp + "Webaktion-Parameter" },

    LIST_ADD_LABEL: { text: tHelp + "Füge einen Eintrag hinzu" },
    KEY: { text: tHelp + "Key" },
    DATA: { text: tHelp + "Daten" },
    LIST_ENTRY: { text: tHelp + "Eintrag" },
    LIST_ENTRY_ITERATABLE: { text: tHelp + "Eintrag {}" },

    PARAMETER_TYPE: { text: tHelp + "Parameter-Typ" },
    MAP_AS_ACTION_PARAM: { text: tHelp + "Als diesen Aktions-Parameter übergeben" },
    PROVIDER_FIELD: { text: tHelp + "Provider-Feld" },
    PROVIDER_FIELD_COLON: { text: tHelp + "Provider-Feld" },
    PROVIDER_COLON: { text: tHelp + "Provider:" },
    CONST_VALUE: { text: tHelp + "Konstanten Wert" },
    MAPPED_CONST_VALUE: { text: tHelp + "Übergebener konstanter Wert" },

    EDIT_NO_DATAPROVIDER: { text: tHelp + "Keinen Datenprovider bearbeiten" },

    PROVIDER_INTERFACE_MAPPING: { text: tHelp + "Auftrags-Interface-Mapping" },
    PROVIDER_VALUERANGE_INTERFACE_MAPPING: { text: tHelp + "Auftrags-Wertebreiche-Interface-Mapping" },

    NONE: { text: tHelp + "Keine" },
    IGNORE: { text: tHelp + "Ignorieren" },
    IGNORE_SLASH_NONE: { text: tHelp + "Keines/Ignorieren" },

    COLUMN_DEFINITION: { text: tHelp + "Spaltendefinition" },
    ADD_COLUMN: { text: tHelp + "Spalte hinzufügen" },

    CHART_PROVIDER_KEY_DESCR: { text: tHelp + "Hinterlegt einen Datenprovider für das Diagramm. Hinzugefügte Datenmengen werden davon überschrieben."},
    CHART_PROVIDER_DEFINTION: { text: tHelp + "Felder zuordnen"},
    CHART_PROVIDER_DEFINTION_DESCR: { text: tHelp + "Hier werden die Datenprovider-Felder den verschiedenen Rollen im Diagramm zugeordnet."},
    CHART_PROVIDER_DEFINTION_LABELFIELD: { text: tHelp + "Datenreihen-Bezeichnung"},
    CHART_PROVIDER_DEFINTION_AXISFIELD: { text: tHelp + "Achsenbeschriftung"},
    CHART_PROVIDER_DEFINTION_VALUEFIELD: { text: tHelp + "Werte"},

    ADD_SORT_COLUMN: { text: tHelp + "Sortier-Spalte hinzufügen" },

    OPTIONS: { text: tHelp + "Optionen" },
    SELECT_DISTINCT: { text: tHelp + "Benutze \"SELECT DISTINCT\"" },
    LIVESICH_DISABLED: { text: tHelp + "Livesich für diese Datenquelle ausschalten" },
    TOP_AMOUNT: { text: tHelp + "Anzahl an Datensätzen die maximal geholt werden dürfen" },
    SAVE: {text: tHelp + "Speichern"},

    // Properties UI-Designer
    REPL_PAGE_DESCR: { text: tHelp + "Aktuelle Seite beim öffnen ersetzen" },
    TEXT_TEXT_DESCR: { text: tHelp + "Der anzuzeigende Text" },
    TEXT_VARIANTE_DESCR: { text: tHelp + "Bestimmt die Art des Textes, z.B. Größe oder Breite des Textes, aus vordefinierten Varianten" },
    COMP_HIDE: { text: tHelp + "Bestimmt, ob die Komponente sichtbar sein soll" },
    COMP_COLOR_SWIZZLE: { text: tHelp + "Erlaubt die Zuordnung der Farbwerte des Themes für die unterliegenen Komponenten zu ändern." },
    COMP_FONT_OVERWRITE: { text: tHelp + "Erlaubt die das überschreiben von Font-Eigenschaften für eine Komponente und ihre Kinder." },
    LABEL_TEXT_DESCR: { text: tHelp + "Der anzuzeigende Text" },
    CHART_LABEL_DESCR: { text: tHelp + "Der Name des Diagramms" },
    CHART_LABELS_DESCR: { text: tHelp + "Die Beschriftungen der Verlaufsachse" },
    CHART_DATASETS_DESCR: { text: tHelp + "Die Datenmengen, welche passend zu den Beschriftungen, Daten geben" },
    CHART_CURRENT_VALUE_DESCR: { text: tHelp + "Der aktuelle Wert eines Tachometers" },
    CHART_MAX_VALUE_DESCR: { text: tHelp + "Der maximlar Wert eines Tachometers" },
    CHART_HORIZONTAL_DESCR: { text: tHelp + "Das Diagramm soll horizontal aufgebaut werden" },
    CHART_STACKED_DESCR: { text: tHelp + "Das Diagramm Datenmengen auf der Hauptachse überlappen lassen" },
    CHART_SHOW_LEGEND_DESCR: { text: tHelp + "Legende für das Diagramm anzeigen" },
    CHART_SHOW_X_ACHSIS_DESCR: { text: tHelp + "X-Achse für das Diagramm anzeigen" },
    CHART_SHOW_Y_ACHSIS_DESCR: { text: tHelp + "Y-Achse für das Diagramm anzeigen" },
    CHART_SHOW_VALUES_AS_EXTRA_Y_ACHSIS_DESCR: { text: tHelp + "Zeige die Werte jedes Balkens als extra Y-Achse" },
    CHART_TYPE_DESCR: { text: tHelp + "Der Typ des Diagramms" },
    CHART_SHOWSELECTEDROWSONLY_DESCR: { text: tHelp + "Sinnvoll, wenn Datenmengen und Achsenbeschriftungen ein Datenprovider-Binding haben." },
    CHART_RANDOMIZE_COLOR_DESCR: { text: tHelp + "Bestimmt, ob zufällige Werte für die Datenmenge genommen werden sollen" },
    CHECKBOX_LABEL_DESCR: { text: tHelp + "Der Text, der die Checkbox beschreibt" },
    PROGRESSBAR_LABEL_DESCR: { text: tHelp + "Der Text, der den Fortschrittsbalken beschreibt" },
    CHECKBOX_VALUE_DESCR: { text: tHelp + "Bestimmt den aktuellen Wert der Checkbox" },
    CHECKBOX_DISABLED_DESCR: { text: tHelp + "Gibt an, ob die Checkbox nur lesbar ist" },
    DROPDOWN_DISABLED_DESCR: { text: tHelp + "Gibt an, ob der Dropdown nur lesbar (disabled) ist" },
    TEXT_INPUT_DISABLED_DESCR: { text: tHelp + "Gibt an, ob das Textfeld nur lesbar ist" },
    TEXT_INPUT_SUBMIT_ACTION_DESCR: { text: tHelp + "Wenn man die ENTER Taste drückt, wird diese Aktion ausgeführt" },
    HIDE_WHEN_EMPTY_DESCR: { text: tHelp + "Soll das Element versteckt werden wenn keine Auswahlmöglichkeiten zur Verfügung stehen?" },
    DISABLED_WHEN_EMPTY_DESCR: { text: tHelp + "Soll das Element nur lesbar sein wenn keine Auswahlmöglichkeiten zur Verfügung stehen?" },
    RESOURCE_FILE_DESCR: { text: tHelp + "Die Datei, welche in dieser Komponente hinterlegt wird" },
    RESOURCE_HEIGHT_DESCR: { text: tHelp + "Die Höhe, welche die Resource verbraucht. Leer bedeutet automatisch" },
    BUTTON_HOTKEY_DESCR: { text: tHelp + "Der Hotkey, welcher die in der Button hinterlegte Webaktion ausführt" },
    BUTTON_PRIMARY_DESCR: { text: tHelp + "Gibt an, ob der Button als primärer Button gerendert werden soll" },
    BUTTON_DISABLED_DESCR: { text: tHelp + "Gibt an, ob der Button klickbar sein soll" },
    BUTTON_SIMPLIFIED_DESCR: { text: tHelp + "Lässt den Button etwas simpler aussehen, z.B. gut für Buttons innerhalb von Endless-Tables" },
    BUTTON_WEB_ACTION_PARAMS_DESCR: { text: tHelp + "Pflegt die in der Button hinterlegte Webaktion" },
    BUTTON_GROW_DESCR: { text: tHelp + "Gibt an, ob der Button die volle Breite des Parent-Elements benutzen soll (nützlich z.B. wenn man Buttons unter einander anzeigen will)" },
    ENDLESS_TABLE_NAME_DESCR: { text: tHelp + "Ein allgemeiner Name, der die Endless-Table beschreibt" },
    ENDLESS_TABLE_NAME_TITLE_DESCR: { text: tHelp + "Eine Bezeichnung, die spziell für diese Endless-Table greift (z.B. eine Tabelle für einen bestimmten Träger)." },
    ENDLESS_TABLE_NAME_SUBTITLE_DESCR: { text: tHelp + "Eine sekundäre Bezeichnung, die spziell für diese Endless-Table greift (z.B. eine Tabelle für einen bestimmten Träger)." },
    ENDLESS_TABLE_PROVIDER_KEY_DESCR: { text: tHelp + "Hinterlegt einen Datenanbieter in der Endless-Table" },
    CHARTS_PROVIDER_KEY_DESCR: { text: tHelp + "Hinterlegt einen Datenanbieter fürs Diagramm" },
    ENDLESS_TABLE_CAN_FILTER_DESCR: { text: tHelp + "Bestimmt ob die Endless-Table Filter, z.B. Filter-Dropdowns, benutzt werden sollen" },
    ENDLESS_TABLE_CAN_SORT_DESCR: { text: tHelp + "Bestimmt ob die Endless-Table eine Suchfunktion hat" },
    ENDLESS_TABLE_CUSTOM_COLUMN_DEFINITION_DESCR: { text: tHelp + "Lege eine individualisierte Spaltendefinition an, welche Größe, Sichtbarkeit, Reihenfolge etc. bestimmt" },
    ENDLESS_TABLE_HEADER_DEFINITION_DESCR: { text: tHelp + "Hinterlege eine Liste von Aktionen, die als Buttons im Table-Header angezeigt werden" },
    ENDLESS_TABLE_FILTERS_DEFINITION_DESCR: { text: tHelp + "Hinterlege eine Liste von Dropdown-Eigenschaften, die als Dropdown-Filter im Table-Header angezeigt werden" },
    ENDLESS_TABLE_DEFAULT_ACTION_DESCR: { text: tHelp + "Die Aktion, die bei doppelklick auf eine Zeile ausgeführt wird. Markiert auch den entsprechenden Button falls in den Tabellen-Aktionen hinterlegt" },
    ENDLESS_TABLE_SELECTION_MODE_DESCR: { text: tHelp + "Bestimmt, ob mehrere Einträge in der Endless-Table gleichzeitig ausgewählt werden können" },
    ENDLESS_TABLE_AUTO_GROUPING_DESCR: { text: tHelp + "Versucht die in der Tabelle angezeigten Daten nach einem Feld zu gruppieren." },
    ENDLESS_TABLE_DATA_POSITIONING_DESCR: { text: tHelp + "Versucht, falls diese Seite einen Result-Wert von einer anderen Seite bekommen hat, den Datensatz auf das gewünschte Feld mit Hilfe des Result-Werts zu positionieren." },
    ENDLESS_TABLE_EXCEL_EXPORT_DESCR: { text: tHelp + "Legt fest, ob die Daten der Tabelle als Excel-Datei exportiert werden können" },
    CROSSTABLE_AGGREGATE_SUM: { text: tHelp + "Summe"},
    CROSSTABLE_AGGREGATE_MAX: { text: tHelp + "Maximum"},
    CROSSTABLE_AGGREGATE_MIN: { text: tHelp + "Minimum"},
    ORDERS_ENDLESS_TABLE_PROVIDER_DATA_DESCR: { text: tHelp + "Gibt an woher die Daten für die Endless-Table kommen" },
    ORDERS_ENDLESS_TABLE_CONTROL_DESCR: { text: tHelp + "Kontrolle statt Tabelle anzeigen" },
    ORDER_ARTICLES_LIST_DESCR: { text: tHelp + "Nimmt über das hinterlegte Binding die Artikel-Liste aus dem Contract" },
    ORDER_DATE_LIST_DESCR: { text: tHelp + "Nimmt über das hinterlegte Binding die Datums-Liste aus dem Contract" },
    ORDER_DATE_SELECTED_DESCR: { text: tHelp + "Ausgewähltes Datum" },
    ORDER_DATE_PRESELECTED_DESCR: { text: tHelp + "Vorausgewähltes Datum" },
    AUSSTA_LIST_READER_DESCR: { text: tHelp + "Von dieser Variable wird die aktuelle Ausstattung ausgelesen" },
    AUSSTA_LIST_WRITER_DESCR: { text: tHelp + "In diese Variable wird die aktuelle Ausstattung geschrieben" },
    KDARTI_ID_READER_DESCR: { text: tHelp + "Aus dieser Variable soll aus den Bindings die Liste der KdArti-IDs gelesen werden" },
    KDARTI_BEZ_READER_DESCR: { text: tHelp + "Aus dieser Variable soll aus den Bindings die Liste der KdArti-Bezs gelesen werden" },
    ART_GROE_READER_DESCR: { text: tHelp + "Aus dieser Variable soll aus den Bindings die Liste der ArtGroes aller aktuellen KdArtis gelesen werden" },
    AFTER_BENCHMARK_ACTION_DESCR: { text: tHelp + "Führt diese Webaktion aus, sobald der Benchmark fertig ist." },
    AFTER_BENCHMARK_TIMEOUT_DESCR: { text: tHelp + "Verzögert die Ausführung der Webaktion um diese Zeit in Millisekunden." },
    TRANSLATION_CONTEXT_DESCR: { text: tHelp + "Ein Kontext in dem dieser String benutzt wird, um Namens-Kollisionen zumeiden (z.B. Birne ist doppeldeutig, ein möglicher Kontext wäre hier \"Frucht\")" },
    ALLOW_MULTILINE_DESCR: { text: tHelp + "Das Label darf mehrzeilig sein" },
    AUTO_ADJUST_HEIGHT_DESCR: { text: tHelp + "Passt die Höhe dem Text an. Muss mehrzeilig sein." },
    FORM_REQUIRED_DESCR: { text: tHelp + "Es wird eine Fehlermeldung angezeigt, sobald das Speichern des Auftrags fehlschlägt und dieses Feld leer ist. Die Prüfung muss serverseitig im Auftrag stattfinden." },
    IGNORE_TRANSLATION_DESCR: { text: tHelp + "Darf der Inhalt übersetzt werden?" },
    IGNORE_CACHE_DESCR: { text: tHelp + "" },
    CAN_COLLAPSE_DESCR: { text: tHelp + "Groupbox kann geschlossen werden" },
    COLLAPSE_DIR_DESCR: { text: tHelp + "Richtung, in die die Groupbox geschlossen wird. (Box an rechtem Rand: rechts, Box an linkem Rand: links)" },
    ON_CLICK_ACTION_DESCR: { text: tHelp + "Webaktion, die ausgeführt wird, wenn diese Komponente geklickt wird" },
    NORMAL_FONT_WEIGHT_DESCR: { text: tHelp + "Der Inhalt soll mit einer normalen Text-Dicke angezeigt werden" },
    DUPLICATION_AMOUNT_DESCR: { text: tHelp + "Die Anzahl an Dublikationen, welche diese Komponente von ihren Kinder-Komponenten machen soll. Generell ist es ratsamer Bindings zu benutzen anstatt einen festgesetzten Wert zu nehmen" },
    DYNAMIC_PAGE_TITLE_DESCR: { text: tHelp + "Der Titel der dynamischen Seiten, welcher z.B. im Breadcrump angezeigt wird" },
    DYNAMIC_PAGE_SECONDARY_TITLE_DESCR: { text: tHelp + "Ein sekundärer Titel der dynamischen Seiten, welcher z.B. im Breadcrump als extra Information angezeigt wird. Nützlich um z.B. eine ID zu hinterlegen." },
    DYNAMIC_CATEGORY_DESCR: { text: tHelp + "Die Kategorie der dynamischen Seite, welche Seiten mit gleicher Kategorie automatisch zusammenfasst" },
    DYNAMIC_MENUICON_DESCR: { text: tHelp + "Das Icon, welches in der Seite hinterlegt ist, z.B. für die Navigation" },
    DYNAMIC_NAV_ACTION_DESCR: { text: tHelp + "Die Webaktion, welche die Seite aus der Navigation aufruft" },
    ICON_ICON_DESCR: { text: tHelp + "Das Icon, welches diese Komponente anzeigen soll" },
    COMBOBOX_LABEL_DESCR: { text: tHelp + "Der Text, der die Combobox beschreibt" },
    COMBOBOX_VALUE_DESCR: { text: tHelp + "Der aktuelle Wert der Combobox" },
    COMBOBOX_ALLOWFREEFORM_DESCR: { text: tHelp + "Gibt an, ob das Eingabefeld der Combobox von den hinterlegten Combobox-Data-Items abweichen darf" },
    COMBOBOX_MULTI_SELECT_DESCR: { text: tHelp + "Gibt an, ob das eine Mehrfachauswahl der Items erlaubt ist. Diese ist Komma separiert." },
    COMBOBOX_OPTIONS_DATA_DESCR: { text: tHelp + "Die Liste von Einträgen, welche die Combobox als Auswahl bereitstellt" },
    DROPDOWN_LABEL_DESCR: { text: tHelp + "Der Text, der die Dropdown beschreibt" },
    DROPDOWN_VALUE_DESCR: { text: tHelp + "Der aktuelle Wert der Dropdown, welcher mit einem Eintrag in den Dropdown-Data-Items übereinstimmen muss" },
    DROPDOWN_MULTI_SELECT_DESCR: { text: tHelp + "Gibt an, ob das eine Mehrfachauswahl der Items erlaubt ist. Diese ist Komma separiert." },
    DROPDOWN_TEXT_ITEMS_DESCR: { text: tHelp + "Texte, welche die Dropdown-Data-Items beschriften (sollte gleiche Menge haben wie die Data-Items)" },
    DROPDOWN_DATA_ITEMS_DESCR: { text: tHelp + "Die Wertemenge der auswählbaren Dropdown-Einträge" },
    RADIOGROUP_LABEL_DESCR: { text: tHelp + "Ein Label, welches als eine Art Überschrift der Radiobox dient" },
    TEXTINPUT_LABEL_DESCR: { text: tHelp + "Der Text, der den Text-Input beschreibt" },
    TEXTINPUT_DESCRIPTION_DESCR: { text: tHelp + "Kleine Beschreibung unterhalb des Text-Inputs" },
    PLACEHOLDER_DESCR: { text: tHelp + "Platzhalter wenn leer." },
    TEXTINPUT_VALUE_DESCR: { text: tHelp + "Der aktuelle Wert des Text-Inputs" },
    DATEPICKER_VALUE_FORMAT_DESCR: { text: tHelp + "Wie soll das Datum im Hintergrund formatiert werden?" },
    DATEPICKER_DISPLAY_FORMAT_DESCR: { text: tHelp + "Wie soll das Datum in der Anzeige formatiert werden?" },
    DATEPICKER_RANGE_TYPE_DESCR: { text: tHelp + "Wie soll die Auswahl im Kalendar dargestellt werden?" },
    DATEPICKER_VALUE_TODAY_DESCR: { text: tHelp + "Wählt automatisch heute aus" },
    DATEPICKER_MONTHPICK_VISIBLE_DESCR: { text: tHelp + "Soll die Monats-Auswahl sichtbar sein?" },
    TEXTINPUT_INPUT_TYPE_DESCR: { text: tHelp + "Der Datentyp des Text-Inputs. Überschrieben durch gebundene Werte." },
    GRID_COLUMNS_DESCR: { text: tHelp + "Eine durch ein Leerzeichen getrennte Liste von Größen, welche die einzelnen Spalten abbilden" },
    GRID_ROWS_DESCR: { text: tHelp + "Eine durch ein Leerzeichen getrennte Liste von Größen, welche die einzelnen Zeilen abbilden" },
    GRID_VERTICAL_FILL_DESCR: { text: tHelp + "Fülle das Parent-Element vertikal zu 100%" },
    GRID_HORIZONTAL_FILL_DESCR: { text: tHelp + "Fülle das Parent-Element horizontal zu 100%" },
    GRID_ALIGN_ITEMS_DESCR: { text: tHelp + "Ausrichtung der Items (vertikal)" },
    GRID_COL_GAP_DESCR: { text: tHelp + "Abstand zwischen den Spalten" },
    GRID_ROW_GAP_DESCR: { text: tHelp + "Abstand zwischen den Zeilen" },
    GRID_ITEM_COLUMN_DESCR: { text: tHelp + "Der Index der Spalte des Grids, in welcher sich dieses Item befindet (One-Based-Indexing)" },
    GRID_ITEM_ROW_DESCR: { text: tHelp + "Der Index der Zeile des Grids, in welcher sich dieses Item befindet (One-Based-Indexing)" },
    GRID_ITEM_AS_FLEX_CONTAINER_DESCR: { text: tHelp + "Das Grid-Item soll sich wie ein Flex-Container verhalten, sodass auch die Stack-Item-Eigenschaften des Elements berücksichtigt werden." },
    REACT_GRID_COLUMNS_DESCR: { text: tHelp + "Anzahl der Spalten" },
    REACT_GRID_ROWHEIGHT_DESCR: { text: tHelp + "Höhe einer Zeile des Grids" },
    REACT_GRID_LAYOUT_DESCR: { text: tHelp + "Das Layout der jeweiligen benannten Children-Items" },
    GROUPBOX_HEADER_DESCR: { text: tHelp + "Die Überschrift der Groupbox" },
    GROUPBOX_ADDITIONAL_HEADER_DESCR: { text: tHelp + "Zusatztext, der bei eingeklappter Groupbox angezeigt wird" },
    GROUPBOX_OPENED_DESCR: { text: tHelp + "Gibt an, ob die Groupbox geöffnet ist, also der Inhalt sichtbar ist" },
    GROUPBOX_CONTENT_AS_FLEX_CONTAINER_DESCR: { text: tHelp + "Lässt den Inhalt der Groupbox automatisch als Flex-Container handlen" },
    TAB_ITEM_HEADER_DESCR: { text: tHelp + "Die Überschrift des Tab-Items" },
    STACK_ITEM_GROW_RATIO_DESCR: { text: tHelp + "Gibt an in welchem Verhältnis das Element wächst im Vergleich zu anderen Elementen des Parent-Elements" },
    STACK_ITEM_SHRINK_RATIO_DESCR: { text: tHelp + "Gibt an in welchem Verhältnis das Element schrumpft im Vergleich zu anderen Elementen des Parent-Elements" },
    STACK_ITEM_ALIGNMENT_DESCR: { text: tHelp + "Die Ausrichtung dieses Elementes im Stack auf der X-Achse (für vertikale Stacks) bzw. Y-Achse (für horizontale Stacks)" },
    STACK_ITEM_FLEX_BASIS_DESCR: { text: tHelp + "Die Breite/Höhe (je nachdem ob der Stack horizontal ist) die das Element mindestens nimmt" },
    STACK_ITEM_MIN_WIDTH_DESCR: { text: tHelp + "Die minimale Breite, die das Element nimmt (im Normalfall setzt man diese Property nur für vertikalen Stacks)" },
    STACK_ITEM_MAX_WIDTH_DESCR: { text: tHelp + "Die maximale Breite, die das Element nimmt (im Normalfall setzt man diese Property nur für horizontale Stacks)" },
    STACK_ITEM_MIN_HEIGHT_DESCR: { text: tHelp + "Die minimale Höhe, die das Element nimmt (im Normalfall setzt man diese Property nur für horizontale Stacks)" },
    STACK_ITEM_MAX_HEIGHT_DESCR: { text: tHelp + "Die maximale Höhe, die das Element nimmt (im Normalfall setzt man diese Property nur für vertikalen Stacks)" },
    STACK_HORIZONTAL_DESCR: { text: tHelp + "Bestimmt ob der Stack horizontal aufgebaut wird (bzw. vertikal)" },
    STACK_WRAP_DESCR: { text: tHelp + "Bestimmt ob der Stack in eine neue Zeile/Spalte (je nachdem ob horizontal oder vertikal) geht sobald der ganze Platz verbraucht wurde" },
    STACK_VERTICAL_FILL_DESCR: { text: tHelp + "Bestimmt ob der Stack 100% der Höhe des Parent-Elements nimmt" },
    STACK_HORIZONTAL_ALIGN_DESCR: { text: tHelp + "Die horizontale Ausrichtung der Children-Elemente" },
    STACK_VERTICAL_ALIGN_DESCR: { text: tHelp + "Die vertikale Ausrichtung der Children-Elemente" },
    STACK_CHILDREN_GAP_DESCR: { text: tHelp + "Der Abstand zwischen den Children-Elemente" },
    WRITE_WEEK_DAY_DESCR: { text: tHelp + "Der im binding hinterlegte Binding-Typ wird benutzt, um den ausgewählten Wochentag zu setzen" },
    WRITE_DATE_PRETTY_DESCR: { text: tHelp + "Das ausgewählte Datum wird in das Binding geschrieben (lesbares Datum)" },
    READ_ONLY_DESCR: { text: tHelp + "Komponente ist schreibgeschützt. Wert kann sich nicht ändern." },
    TEXTINPUT_AUTO_FOCUS: { text: tHelp + "Automatischer Fokus" },
    TEXTINPUT_AUTO_FOCUS_DESCR: { text: tHelp + "Der Fokus wird automatisch auf dieses Eingabefeld gesetzt." },
    TEXTINPUT_CLEAR_SUBMIT: { text: tHelp + "Feld bei Submit leeren" },
    TEXTINPUT_CLEAR_SUBMIT_DESCR: { text: tHelp + "Das Feld wird nach einem Submit geleert." },
    TEXTINPUT_MIN_NUMBER: { text: tHelp + "Number: MinValue" },
    TEXTINPUT_MIN_NUMBER_DESCR: { text: tHelp + "Kleinstmögliche Zahl" },
    TEXTINPUT_MAX_NUMBER: { text: tHelp + "Number: MaxValue" },
    TEXTINPUT_MAX_NUMBER_DESCR: { text: tHelp + "Größtmögliche Zahl" },

    INFO_WEBACTION_PAGE_FILTER: { text: tHelp + "Webaktionen dieser Seite auswählen" },
    INFO_WEBACTION_CATEGORY_FILTER: { text: tHelp + "Webaktionen, welche diese Kategorie hinterlegt haben, auswählen" },
    INFO_WEBACTION_DESCR: { text: tHelp + "Die Webaktion, welche ausgeführt wird, wenn der Button, welcher sie hinterlegt, geklickt wird" },

    PROVIDER_TO_EDIT: { text: tHelp + "Datenanbieter, der editierbar wird" },
    INFO_PROVIDER_TO_EDIT_DESCR: { text: tHelp + "Dieser Datenanbieter wird bei Ausführung der Aktion auf editierbar gestellt" },
    PROVIDER_TO_CANCEL: { text: tHelp + "Datenanbieter, der wieder readonly wird" },
    INFO_PROVIDER_TO_CANCEL_DESCR: { text: tHelp + "Dieser Datenanbieter wird bei Ausführung der Aktion wieder auf nicht editierbar gestellt" },
    CONTRACT_TO_INIT: { text: tHelp + "Auftrag, der initiailisiert wird" },
    INFO_CONTRACT_TO_INIT_DESCR: { text: tHelp + "Dieser Auftrag wird auf dem Server initialisiert, sodass eine Kommunikation zwischen Client und Server für diesen statt finden kann" },
    CONTRACT_TO_FULFILL: { text: tHelp + "Auftrag, der ausgeführt werden soll" },
    INFO_CONTRACT_TO_FULFILL_DESCR: { text: tHelp + "Dieser Auftrag wird versucht ausgeführt zu werden, sodass der Server den Auftragt abschließt bzw. mögliche Rückfragen an den Client gibt" },
    CONTRACT_FUNCTION_CALL: { text: tHelp + "Auftrag, der die auszuführende Funktion bereitstellt" },
    INFO_CONTRACT_FUNCTION_CALL_DESCR: { text: tHelp + "Dieser Auftrag muss die Funktion bereitstellen, welche ausgeführt werden soll" },
    CONTRACT_ADD_EDIT_RECORD: { text: tHelp + "Auftrag, für den der hinterlegte Dataprovider ein neuer Editier-Eintrag hinzu gefügt werden soll" },
    INFO_CONTRACT_ADD_EDIT_RECORD_DESCR: { text: tHelp + "Diesem Auftrag wird ein Editier-Eintrag hinzu gefügt" },
    PROVIDER_ADD_EDIT_RECORD: { text: tHelp + "Datenanbieter, der einen neuen Editier-Eintrag bekommt" },
    INFO_PROVIDER_ADD_EDIT_RECORD_DESCR: { text: tHelp + "Dieser Datenanbieter bekommt einen neuen Editier-Eintrag, um quasi eine Liste von editierten/angelegten Daten ab zu bilden" },

    // Datasource-Designer
    LINKS_OF_THIS_FIELD: { text: tHelp + "Verlinkungen dieses Feldes" },
    AGGR_FUNC_OF_THIS_FIELD: { text: tHelp + "Aggregierungs-Funktion auf dieses Feld anwenden" },
    HAVING_OF_FIELD: { text: tHelp + "HAVING ... (SQL)" },
    WHERE_OF_FIELD: { text: tHelp + "WHERE ... (SQL)" },
    SET_LINKS_OF_THIS_FIELD: { text: tHelp + "Setze Verlinkungen dieses Feldes" },
    FIELD_HAS_LINKS_TITLE: { text: tHelp + "Dieses Feld unterstützt automatische Verlinkungen" },
    SET_AGGREGATION_SETTINGS_OF_FIELD: { text: tHelp + "Setze Aggregierungs-Funktion dieses Feldes" },
    SET_SETTINGS_OF_FIELD: { text: tHelp + "Setze feldspezifische Optionen" },
    SET_LIVESICH_OF_FIELD: { text: tHelp + "Sichtbarkeiten-Beschränkung" },
    LINK: { text: tHelp + "Verlinkung" },
    LINK_ITERATABLE: { text: tHelp + "Verlinkung {}" },
    LINKED_TABLE: { text: tHelp + "Verlinkte Tabelle:" },
    LINKED_FIELD: { text: tHelp + "Verlinktes Feld:" },
    LINK_SQL_OP: { text: tHelp + "SQL-Opeartor:" },
    LINKING_METHOD: { text: tHelp + "Verlinkungs-Methode" },
    ADD_LINK: { text: tHelp + "Füge Verlinkung hinzu" },
    JOIN_LIST: { text: tHelp + "Aktive Joins" },
    FILTER_CATEGORIES: { text: tHelp + "Filter-Kategorien" },
    WITHOUT_FILTER_CATEGORY: { text: tHelp + "Ohne Kategorie" },
    ADD_FILTER_TO_CATEGORY: { text: tHelp + "Füge einen Filter zur Kategorie hinzu" },
    FILTER_LOGIC: { text: tHelp + "SQL (WHERE ...)" },
    FILTER_LOGIC_DESCR: { text: tHelp + "An diesen Filter gebundene Variablen werden mit $VALUE$ eingefügt. Falls eine Liste gebunden wird, diese mit dem in-Schlüsselwort aber ohne Klammern einfügen." },
    JOIN_COND_LOGIC: { text: tHelp + "SQL (JOIN xxx ON ...)" },
    FILTER_DEFAULT: { text: tHelp + "Standardmäßig aktiv" },
    FILTER_REQUIRED: { text: tHelp + "Filter notwendig (hover für mehr Informationen)" },
    FILTER_EARLY: { text: tHelp + "vor dem Gruppieren filtern" },
    FILTER_DEFAULT_TITLE: { text: tHelp + "Standardauswahl dieser Kategorie" },
    FILTER_REQUIRED_TITLE: { text: tHelp + "Dieser Filter ist notwendig, bevor Daten vom Server gesendet werden. Diese Option funktioniert nur für Filter, die von sich dynamisch ändernden Werten abhängen." },
    FILTER_EARLY_TITLE: { text: tHelp + "Dieser Filter wird angewendet, bevor eine Aggregatsfunktion greift. Er kann auch halb-ausgewählte Felder beinhalten." },
    NO_FILTER_SELECTED: { text: tHelp + "Keine Filter-Kategorie ausgewählt" },
    NO_JOIN_SELECTED: { text: tHelp + "Kein Join ausgewählt" },
    DATASOURCE_INLINE: { text: tHelp + "Datenquelle"},
    RESOURCE_INLINE: { text: tHelp + "Resource"},
    NEWS_INLINE: { text: tHelp + "News"},

    DATASOURCE_GETSQL: { text: tHelp + "SQL anzeigen"},
    VIRTTABLES: { text: tHelp + "Virtuelle Tabellen"},
    CHOOSEVIRTTABLE: { text: tHelp + "Virtuelle Tabelle auswählen:"},
    ADDVIRTTABLE: { text: tHelp + "Virtuelle Tabelle hinzufügen"},
    MODIFYVIRTTABLES: { text: tHelp + "Virtuelle Tabellen anpassen"},
    VIRTFIELDS: { text: tHelp + "Virtuelle Felder"},
    ADDVIRTFIELD: { text: tHelp + "Virtuelles Feld hinzufügen"},
    VIRTTABLEPLACEHOLDER: {text: tHelp + "Platzhalter"},
    VIRTTABLEFILTERCAPTION: {text: tHelp + "Von virtueller Tabelle:"},
    MODIFYVIRTFIELDS: { text: tHelp + "Virtuelle Felder anpassen"},
    TABLE: { text: tHelp + "Tabelle"},
    FIELDS: { text: tHelp + "Felder"},
    ADDFIELD: { text: tHelp + "Virtuelles Feld hinzufügen"},
    FIELDNAME: { text: tHelp + "Feldname"},
    DATATYPE: {text: tHelp + "Datentyp"},
    REFTABLE: { text: tHelp + "Ref-Tabelle"},
    REFFIELD: { text: tHelp + "Ref-Feld"},

    SQLSCRIPTS: { text: tHelp + "SQL-Skripts"},
    PRESQL: { text: tHelp + "SQL, welches vor der Select-Auswertung ausgeführt wird"},
    PRESQL_DESCR: { text: tHelp +  "Hier können zum Beispiel temporäre Tabellen erstellt werden."},
    SELECTSQL: { text: tHelp + "SQL, in welchem die Select-Auswertung ausgeführt wird"},
    SELECTSQL_DESCR: { text: tHelp +  "Hier soll ein Select-Befehl mit den Feldern gemäß der angegebenen Felder ausgeführt werden."},
    POSTSQL: { text: tHelp + "SQL, welches nach der Select-Auswertung ausgeführt wird"},
    POSTSQL_DESCR: { text: tHelp +  "Hier können zum Beispiel temporäre Tabellen gelöscht werden."},

    // Action
    ACTION_LOGIC_NAME: { text: tHelp + "Webaktion-Logik" },
    HINT: { text: tHelp + "Hinweis" },
    ACTION_QUESTION: { text: tHelp + "Abfrage vor Ausführung" },
    ACTION_PARAMETERS: { text: tHelp + "Aktion-Parameter" },
    ACTION_PARAMETER: { text: tHelp + "Aktion-Parameter" },
    ACTION_PARAMETER_ITERATABLE: { text: tHelp + "Aktion-Parameter {}" },
    ADD_ACTION_PARAMETER: { text: tHelp + "Füge Aktions Parameter hinzu" },
    HAS_DEFAULT_VALUE: { text: tHelp + "Hat Standardwert" },
    DEFAULT_VALUE: { text: tHelp + "Standardwert" },
    DESCRIPTION: { text: tHelp + "Beschreibung" },
    IS_OPTIONAL: { text: tHelp + "Ist optional" },

    WEBACTION_INLINE: { text: tHelp + "Webaktion"},

    SYNC_PARAMETERS_TO_PAGE_VAR: { text: tHelp + "Synchronisiere Aktion-Parameter zu den Page-Variablen" },
    SYNC_PARAMETERS_TO_CONTRACT_INTERFACE: { text: tHelp + "Synchronisiere Aktion-Parameter zum Auftrags-Interface" },

    // Action-Logic
    ACTION_ADD: { text: tHelp + "Webaktion hinzufügen" },
    ACTION_LOGIC_OPEN_PAGE: { text: tHelp + "Öffne diese Seite" },
    ACTION_LOGIC_EDIT_PROVIDER: { text: tHelp + "Versuche eine Datenanbieter bearbeitbar zu machen" },
    ACTION_LOGIC_SAVE_PROVIDER: { text: tHelp + "Versuche die aktuellen Bearbeitungen des Datenanbieters zu speichern" },
    ACTION_LOGIC_TRY_INIT_CONTRACT: { text: tHelp + "Versuche eine Datenanbieter bearbeitbar zu machen und einen Auftrag zu starten" },
    ACTION_LOGIC_CANCEL_CONTRACT: { text: tHelp + "Brich einen Auftrag ab" },
    ACTION_LOGIC_TRY_CANCEL_CONTRACT: { text: tHelp + "Versucht einen Auftrag abzubrechen. Ohne Fehlermeldung falls Auftrag nicht gestartet." },
    ACTION_LOGIC_EXEC_CONTRACT: { text: tHelp + "Initialisiere einen Auftrag und versuche ihn direkt ab zuschließen" },
    ACTION_LOGIC_CALL_CONTRACT_FUNC: { text: tHelp + "Rufe eine Funktion auf, welche der Auftrag bereitstellt" },
    ACTION_LOGIC_ADD_CONTRACT_EDIT_RECORD: { text: tHelp + "Füge einen editierbaren Datensatz zu dem aktuell editierbaren Auftrag hinzu (quasi für eine Liste von Datensätzen)" },
    ACTION_LOGIC_REM_CONTRACT_EDIT_RECORD: { text: tHelp + "Lösche den letzten editierbaren Datensatz, falls mindestens 2 vorhanden sind (quasi für eine Liste von Datensätzen)" },
    ACTION_LOGIC_BROWSER_BACK: { text: tHelp + "In der Browser History einen Schritt zurück gehen (Zurück-Klick, Client-Side only)" },
    ACTION_LOGIC_RETURN: { text: tHelp + "In der Browser History einen Schritt zurück gehen (Zurück-Klick, Client-Side only). Zusätzlich wird ein Wert in das Result-Register geschrieben." },

    IMPINJ_PROTOTYP: { text: tHelp + "IMPINJ-Prototyp" },

    MAX_CONTENT: { text: tHelp + "Größter Inhalt" },
    MAX_CONTENT_PLUS_EXTRA_MARGIN: { text: tHelp + "Größter Inhalt plus extra Abstand" },

    TABLES: { text: tHelp + "Tabellen" },
    FILTER: { text: tHelp + "Filter" },

    ACCEPT: { text: tHelp + "Akzeptieren" },
    AUTOMATIC_LINKING: { text: tHelp + "Automatische Verknüpfung" },
    COPYME: { text: tHelp + "COPY" },

    ENABLES_DRAG_AND_DROP: { text: tHelp + "Ermöglicht Drag & Drop" },
};
