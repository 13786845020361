import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { advcatch, advlog } from "@utils/logging";
import { Blob } from "buffer";
import Pako from "pako";
import { useDebugValue, useMemo } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { atom } from "recoil";

import { WebSocketLike } from "react-use-websocket/dist/lib/types";
import useIsMounted from "./misc/useIsMounted";
import useSsr from "./misc/useSsr";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "./recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "./recoil-overload/useAdvRecoilValue";
import useAdvSetRecoilState from "./recoil-overload/useAdvSetRecoilState";
import { useAdvEvent } from "./useAdvEvent";
import { useSystemConfig } from "./useSystemConfig";
import { useWaitForLogin } from "./useWaitForLogin";

const netMsgInOrder = atom({
    key: "netMsgInOrder",
    default: new Array<string>(),
});

const netMsgUnordered = atom({
    key: "netMsgUnordered",
    default: new Array<string>(),
});

const netCurSocket = atom<WebSocketLike | null>({
    key: "netCurSocket",
    default: null,
});

export const netCloseSocketForced = (tb: TAdvTransactionInterface) => () => {
    const { reset, get } = tb;
    const socket = get(netCurSocket);
    reset(netCurSocket);
    reset(netMsgInOrder);
    reset(netMsgUnordered);
    if (socket != null) {
        socket.close();
    }
};

/**
 * Ein specieller Hook der alle Websocket-Events handled.
 * Sollte nur einmal pro app existieren
 */
export function useAdvSocketServer() {
    const { isServer } = useSsr();

    const msgInOrder = useAdvRecoilValue(netMsgInOrder);
    const msgUnordered = useAdvRecoilValue(netMsgUnordered);

    const curSocketSetter = useAdvSetRecoilState(netCurSocket);

    const { executeEvent } = useAdvEvent();

    const { waitForLogin } = useWaitForLogin();

    const { isMounted } = useIsMounted();

    const { getDataServerUrl, dataServerAlias } = useSystemConfig();

    const processMessage = useAdvCallback(
        function (data: ArrayBuffer) {
            if (isServer) return; // Der Server soll keine Daten verarbeiten

            let eventExtraInfo: string = "";
            function getUncompressedData() {
                const evDataBuffer = new Uint8Array(data);
                // decompression if needed
                if (evDataBuffer.byteLength >= 1) {
                    let findIndex = 0;
                    if (evDataBuffer.at(0) == "~".charCodeAt(0)) {
                        findIndex = 0;
                        while (evDataBuffer.byteLength > findIndex + 1) {
                            ++findIndex;
                            if (evDataBuffer.at(findIndex) == "~".charCodeAt(0)) {
                                ++findIndex;
                                break;
                            }
                        }
                        eventExtraInfo = new TextDecoder().decode(
                            new Uint8Array(evDataBuffer.buffer, 1, findIndex - 2),
                        );
                    }
                    if (evDataBuffer.at(findIndex) == "$".charCodeAt(0)) {
                        const deCompressed = Pako.ungzip(
                            new Uint8Array(
                                evDataBuffer.buffer,
                                findIndex + 1,
                                evDataBuffer.byteLength - findIndex - 1,
                            ),
                        );
                        advlog(
                            "Decompressed data gzip: ratio: " +
                                (evDataBuffer.byteLength / deCompressed.byteLength)
                                    .toFixed(2)
                                    .toString() +
                                ", compressed len: " +
                                evDataBuffer.byteLength.toString() +
                                ", uncompressed len: " +
                                deCompressed.length.toString(),
                        );
                        return new TextDecoder().decode(deCompressed);
                    } else {
                        return new TextDecoder().decode(
                            new Uint8Array(
                                evDataBuffer.buffer,
                                findIndex,
                                evDataBuffer.byteLength - findIndex,
                            ),
                        );
                    }
                } else {
                    return new TextDecoder().decode(evDataBuffer);
                }
            }

            const evData = getUncompressedData();

            const posBereichName = evData.indexOf("_");
            const posNamePayload = evData.indexOf("|||", posBereichName);
            const bereich = evData.substring(0, posBereichName);
            const name = evData.substring(posBereichName + 1, posNamePayload);
            const payload = evData.substring(posNamePayload + 3);

            let eventData: {};
            try {
                eventData = JSON.parse(payload);
            } catch (e) {
                try {
                    // TODO: Bereits im Server machen
                    eventData = JSON.parse(
                        payload
                            .replaceAll("\r", "")
                            .replaceAll("\n", "")
                            .replaceAll("\t", "") /* TODO: tabs broken im delphi json creator */,
                    );
                } catch (e) {
                    console.error("Couldnt parse Event-Data: " + evData);
                    return;
                }
            }

            advlog("Socket (eingehend): " + bereich + "_" + name, eventData);
            executeEvent(bereich, name, eventData, eventExtraInfo);
        },
        [executeEvent, isServer],
    );

    const onClose = useAdvCallback(function (e: CloseEvent) {
        advlog("onClose");
        console.dir(e);
    }, []);

    const onError = useAdvCallback(function (e: Event) {
        advlog("onError");
        console.dir(e);
    }, []);

    // Callback um die Socket-URL zu ermitteln.
    // Können nicht direkt per Constante auf windows zugreifen,
    // da dies sonst zu einem Fehler führt (window is undefined),
    // weil im Dev das ganze über den Node-Server laufen muss, wo es
    // kein Window gibt
    const getSocketUrl = useAdvCallback(() => {
        return getDataServerUrl();
    }, [getDataServerUrl]);

    const onOpen = useAdvCallback(() => advlog("opened"), []);
    const shouldReconnect = useAdvCallback(() => false, []);
    const onMessage = useAdvCallback(
        (event: WebSocketEventMap["message"]) => {
            (event.data as Blob)
                .arrayBuffer()
                .then((val) => processMessage(val))
                .catch((r) => advcatch("Could not process web msg: ", r));
        },
        [processMessage],
    );
    const filter = useAdvCallback(() => false, []);

    const { sendMessage, readyState, getWebSocket } = useWebSocket(getSocketUrl, {
        onOpen: onOpen,
        share: false,
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: shouldReconnect,
        onMessage: onMessage,
        onClose: onClose,
        onError: onError,
        filter: filter,
        queryParams:
            dataServerAlias !== undefined && dataServerAlias.length > 0
                ? { "adv-alias": dataServerAlias }
                : undefined,
    });

    useAdvEffect(() => {
        curSocketSetter(getWebSocket());
        // this is on purpose, the websocket function is static, but the first run
        // returns null
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [curSocketSetter, getWebSocket, readyState]);

    const sendMsgWrapper = useAdvCallback(
        (msg: string) => {
            if (msg.length >= 256) {
                // compress
                const bufferStr = new Uint8Array(new TextEncoder().encode("$").buffer);
                const bufferZip = Pako.gzip(msg);
                const sendAr = new Uint8Array(bufferStr.byteLength + bufferZip.byteLength);
                sendAr.set(bufferStr, 0);
                sendAr.set(bufferZip, bufferStr.byteLength);
                advlog(
                    "Compressed data gzip: ratio: " +
                        (sendAr.byteLength / msg.length).toFixed(2).toString() +
                        ", compressed len: " +
                        sendAr.byteLength.toString() +
                        ", uncompressed len: " +
                        msg.length.toString(),
                );
                sendMessage(sendAr);
            } else {
                sendMessage(new Uint8Array(new TextEncoder().encode(msg).buffer));
            }
        },
        [sendMessage],
    );

    const checkSendEventDataInternalTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            function (isOrdered: boolean) {
                if (!isOrdered && tb.get(netMsgUnordered).length > 0) {
                    const newArr = [...tb.get(netMsgUnordered)];
                    tb.set(netMsgUnordered, []);
                    while (newArr.length > 0) {
                        sendMsgWrapper(newArr[0]);
                        newArr.shift();
                    }
                }

                if (isOrdered && tb.get(netMsgInOrder).length > 0) {
                    const newArr = [...tb.get(netMsgInOrder)];
                    tb.set(netMsgInOrder, []);
                    while (newArr.length > 0) {
                        sendMsgWrapper(newArr[0]);
                        newArr.shift();
                    }
                }
            },
        [sendMsgWrapper],
    );

    const checkSendEventDataInternalT = useAdvRecoilTransaction(checkSendEventDataInternalTrans, [
        checkSendEventDataInternalTrans,
    ]);

    const checkSendEventDataInternal = useAdvCallback(
        async function () {
            if (isServer || !isMounted()) {
                // Der Server soll keine Daten schicken
                return;
            }

            if (readyState != ReadyState.OPEN) {
                //console.warn("SendEventData while ReadyState != OPEN.\tActual ReadyState:\t" +
                //   ["UNINSTANTIATED" /* -1 */, "CONNECTING", "OPEN", "CLOSING", "CLOSED"][readyState + 1]);
                return;
            }

            checkSendEventDataInternalT(false);

            if (await waitForLogin()) {
                checkSendEventDataInternalT(true);
            }
        },
        [isServer, isMounted, readyState, checkSendEventDataInternalT, waitForLogin],
    );

    useAdvEffect(() => {
        if (msgInOrder.length > 0 || msgUnordered.length > 0)
            checkSendEventDataInternal().catch((r) => {
                advlog("Could not send socket event data: ", r);
                console.trace();
            });
    }, [checkSendEventDataInternal, msgInOrder, msgUnordered]);

    useDebugValue(
        "Connection: " +
            ["UNINSTANTIATED" /* -1 */, "CONNECTING", "OPEN", "CLOSING", "CLOSED"][readyState + 1],
    );

    const thisInstance = useMemo(() => {
        return {
            readyState,
        };
    }, [readyState]);
    return thisInstance;
}

/**
 * Ein Hook der die globale Websockets-Connection abbildet und die States und Funkionen für diese Connection bereitstellt.
 * @returns Der state der Websocket-Verbindung. Funktionen zum senden von Daten.
 */
export function useAdvSocket() {
    const { isServer } = useSsr();

    const sendEventDataTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            function <T>(bereich: string, name: string, data: T, ignoreLoginState = false) {
                if (isServer) {
                    // Der Server soll keine Daten schicken
                    return false;
                }

                if (!ignoreLoginState) {
                    const old = tb.get(netMsgInOrder);
                    const newArr = [...old];
                    newArr.push(bereich + "_" + name + "|||" + JSON.stringify(data));
                    tb.set(netMsgInOrder, newArr);
                } else {
                    const old = tb.get(netMsgUnordered);
                    const newArr = [...old];
                    newArr.push(bereich + "_" + name + "|||" + JSON.stringify(data));
                    tb.set(netMsgUnordered, newArr);
                }

                advlog("Socket (ausgehend): " + bereich + "_" + name, data);
            },
        [isServer],
    );

    const sendEventDataTransInternal = useAdvRecoilTransaction(sendEventDataTrans, [
        sendEventDataTrans,
    ]);

    const sendEventData = useAdvCallback(
        function <T>(bereich: string, name: string, data: T, ignoreLoginState = false) {
            sendEventDataTransInternal(bereich, name, data, ignoreLoginState);
        },
        [sendEventDataTransInternal],
    );

    return { sendEventData, sendEventDataTrans };
}
