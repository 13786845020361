import AdvText from "@components/data/text/text";
import { TAdvContractFieldDataValueChangedNtf } from "@components/dynamic/contracts/types";
import AdvButton from "@components/inputs/button-new/button";
import AdvCheckbox from "@components/inputs/checkbox";
import AdvDropdown, { AdvDropdownText, TAdvDropdownItem } from "@components/inputs/dropdown-new";
import AdvTextInput from "@components/inputs/text-input-new";
import AdvToggle from "@components/inputs/toggle/toggle";
import AdvGrid from "@components/layout/grid/grid";
import AdvGridItem from "@components/layout/grid/grid-item/grid-item";
import AdvGroupbox from "@components/layout/groupbox/groupbox";
import AdvStack from "@components/layout/stack/stack";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import { TAdvCommonProperties } from "@components/other/common-properties";
import AdvIcon from "@components/other/icon/icon";
import AdvLoading, { EAdvLoadingMode } from "@components/other/loading/loading";
import { TPageComponentProps } from "@components/page-component";
import { AnprAllGroupboxOpenAtom, AnprShowKdArApplAtom } from "@data/anprobe-article";
import {
    EDataproviderClientOptions,
    useDataprovider,
} from "@data/dataprovider/data-provider-client";
import { LAN } from "@data/language/strings";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeCustom } from "@feature/Designer/types/component-type";
import { IDropdownOption, SelectableOptionMenuItemType } from "@fluentui/react";
import {
    TAdvValueBindingParams,
    useAdvValueBinder,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilState from "@hooks/recoil-overload/useAdvRecoilState";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { useAdvObjMemo } from "@hooks/useAdvObjMemo";
import { TSuccessClass, useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import useAdvTheme from "@hooks/useAdvTheme";
import {
    AenderungIcon,
    ApplIcon,
    CirclePlusIcon,
    DeleteIcon,
    EmblemIcon,
    NamenschildIcon,
    QuestionIcon,
} from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import { advcatch } from "@utils/logging";
import React, { useMemo, useState } from "react";
import { EAdvArtiType, EAdvKdArApplAutoModus, EAnprobeKdArApplChoice } from "./const";
import {
    TAnprobeApplPlatz,
    TAnprobeArtGroe,
    TAnprobeKdArti,
    TAnprobeTraeAppl,
    TAnprobeTraeApplArt,
    TTraeArti,
    TTraeArtiGroup,
} from "./types";

let newTraeArtiID = -3; // Neue Artikel bekommen eine negative ID
let newTraeApplID = -3;

const DEFAULT_TRAEARTI = {
    ArtGroeID: -1,
    UrsprArtGroeID: -1,
    KdArtiID: -3,
    TraeArtiID: newTraeArtiID,
    Menge: 0,
    MengeAendernWeb: true,
    MaxMengeArt: 999,
    MinMengeArt: 0,
    GroupID: -1,
    KdArApplChoices: [],
    NewTraeAppl: [],
    DeletedTraeApplIDs: [],
    ModifiedTraeAppl: [],
    Initialized: false,
} as TTraeArti;

function ArtiTypeToString(artiTypeID: EAdvArtiType) {
    switch (artiTypeID) {
        case EAdvArtiType.AENDERUNGEN:
            return LAN.ARTITYPE_ALTERATION.text;
        case EAdvArtiType.EMBLEM:
            return LAN.ARTITYPE_EMBLEM.text;
        case EAdvArtiType.NSARTI:
            return LAN.ARTITYPE_NAMETAG.text;
        default:
            return LAN.ARTITYPE_UNKNOWN.text;
    }
}

function KdArtiToStr({ ArtikelNr, ArtikelBez, VariantBez, Variante }: TAnprobeKdArti) {
    return (
        `${ArtikelNr} - ${ArtikelBez}` +
        (VariantBez.trim() == ""
            ? Variante.trim() == ""
                ? ""
                : ` [${Variante}]`
            : ` (${VariantBez})`)
    );
}

type TTraeApplProps = {
    traeAppl: TAnprobeTraeAppl;
    kdArtiID: number;
    index: number;
    isEditMode: boolean;

    traeApplArtArr: TAnprobeTraeApplArt[];
    platzDict: Record<number, TAnprobeApplPlatz[]>;
    traeNsText?: string;

    changeTraeAppl: (traeArtiID: number, traeApplID: number, newData: TAnprobeTraeAppl) => void;
    deleteTraeAppl: (traeArtiID: number, traeApplID: number) => void;
    changeKdArApplChoice: (
        traeArtiID: number,
        traeApplID: number,
        newChoice: EAnprobeKdArApplChoice,
    ) => void;
};
const TraeApplCompImpl = ({
    traeAppl,
    kdArtiID,
    index,
    isEditMode,
    traeApplArtArr,
    platzDict,
    traeNsText,
    changeTraeAppl,
    deleteTraeAppl,
    changeKdArApplChoice,
}: TTraeApplProps) => {
    const traeApplArtOptions: IDropdownOption<any>[] = useMemo(() => {
        if (traeAppl.TraeApplID >= 0) {
            // Wenn diese Applikation eine neue ist, dann alle möglichen Änderungsarten zur Auswahl stellen
            return [
                {
                    key: `ddo_traeapplart_${traeAppl.TraeApplID}_${traeAppl.ApplArtikelID}_${traeAppl.KdArApplID}`,
                    text: `${
                        traeAppl.ArtiTypeID == EAdvArtiType.NSARTI
                            ? `${traeNsText} ${traeAppl.ApplArtiBez}`
                            : traeAppl.ApplArtiBez
                    }`,
                    id: String(-999),
                    data: -999,
                },
            ];
        } else {
            // Wenn es diese Applikation schon gibt, dann nur die aktuelle Änderungsart zur Auswahl stellen (nicht veränderbar)
            const res: IDropdownOption<any>[] = [];
            let lastArtiTypeID = -999; // Gruppierung nach Art der Änderung
            let applArtikelID = -999; // Jede Änderung nur einmal anzeigen (relevant wenn eine Änderung mehrmals als KdArAppl vorhanden)
            traeApplArtArr.forEach((traeApplArt, index) => {
                if (traeApplArt.ArtiTypeID != lastArtiTypeID) {
                    res.push({
                        key: `ddo_traeapplart_artitype_${traeApplArt.ArtiTypeID}`,
                        text: ArtiTypeToString(traeApplArt.ArtiTypeID),
                        data: traeApplArt.ArtiTypeID,
                        itemType: SelectableOptionMenuItemType.Header,
                        disabled: true,
                    } as IDropdownOption<any>);
                    lastArtiTypeID = traeApplArt.ArtiTypeID;
                }
                if (applArtikelID == traeApplArt.ApplArtikelID) return; // Workaround hidden/disabled
                res.push({
                    key: `ddo_traeapplart_${traeAppl.TraeApplID}_${traeApplArt.ApplArtikelID}_${traeApplArt.KdArApplID}`,
                    text: `${traeApplArt.ArtiTypeID == EAdvArtiType.NSARTI ? traeNsText : ""} ${
                        traeApplArt.ApplArtiBez
                    }`,
                    id: String(index),
                    data: index,
                    hidden: applArtikelID == traeApplArt.ApplArtikelID,
                } as IDropdownOption<any>);
                applArtikelID = traeApplArt.ApplArtikelID;
            });
            return res;
        }
    }, [
        traeAppl.TraeApplID,
        traeAppl.ApplArtikelID,
        traeAppl.KdArApplID,
        traeAppl.ArtiTypeID,
        traeAppl.ApplArtiBez,
        traeNsText,
        traeApplArtArr,
    ]);

    const platzArr = useMemo(() => {
        // Nur Namenschilder und Embleme sind u.U. eingeschränkt
        if (
            traeAppl.ArtiTypeID == EAdvArtiType.NSARTI ||
            traeAppl.ArtiTypeID == EAdvArtiType.EMBLEM
        )
            return getDictionaryValue(platzDict, kdArtiID, [], -999);
        else return getDictionaryValue(platzDict, -999, []);
    }, [kdArtiID, platzDict, traeAppl.ArtiTypeID]);

    const platzOptions: IDropdownOption<any>[] = useMemo(() => {
        const res: IDropdownOption<any>[] = [];
        platzArr.forEach((platz, index) => {
            if (traeAppl.ArtiTypeID == EAdvArtiType.NSARTI && platz.PlatzFuerNs == false) return;
            if (traeAppl.ArtiTypeID == EAdvArtiType.EMBLEM && platz.PlatzFuerEmblem == false)
                return;
            res.push({
                key: `ddo_traeappl_${traeAppl.TraeApplID}_platz_${platz.PlatzID}`,
                text: `${platz.PlatzBez}`,
                id: String(index),
                data: index,
                hidden: false,
                disabled: !platz.IsAktiv,
            } as IDropdownOption<any>);
        });
        return res;
    }, [platzArr, traeAppl.ArtiTypeID, traeAppl.TraeApplID]);

    const handleArtChange = useAdvCallback(
        (item: IDropdownOption | undefined): void => {
            if (item === undefined) return;
            const newTraeAppl = {
                ...traeAppl,
                ...traeApplArtArr[Number(item.data)],
                Mass: 0,
                PlatzID: -1,
            } as TAnprobeTraeAppl;
            changeTraeAppl(traeAppl.TraeArtiID, traeAppl.TraeApplID, newTraeAppl);
        },
        [changeTraeAppl, traeAppl, traeApplArtArr],
    );
    const handleMassChange = useAdvCallback(
        (newValue?: string): void => {
            if (newValue === undefined) newValue = "0";
            let newNumberValue = Number(newValue);
            if (isNaN(newNumberValue)) newNumberValue = 0;

            if (traeAppl.Positiv && newNumberValue < 0) newNumberValue = 0;
            if (traeAppl.MassVielfaches > 0)
                newNumberValue =
                    Math.ceil(newNumberValue / traeAppl.MassVielfaches) * traeAppl.MassVielfaches;
            if (traeAppl.MaxMass > 0 && newNumberValue > traeAppl.MaxMass)
                newNumberValue = traeAppl.MaxMass;
            if (traeAppl.MinMass > 0 && newNumberValue < traeAppl.MinMass)
                newNumberValue = traeAppl.MinMass;

            const newTraeAppl = {
                ...traeAppl,
                Mass: newNumberValue,
            } as TAnprobeTraeAppl;
            changeTraeAppl(traeAppl.TraeArtiID, traeAppl.TraeApplID, newTraeAppl);
        },
        [changeTraeAppl, traeAppl],
    );
    const handlePlatzChange = useAdvCallback(
        (item: IDropdownOption | undefined): void => {
            if (item === undefined) return;
            const newValue = item.data;
            if (newValue === undefined) return;
            const newNumberValue = Number(newValue);
            if (isNaN(newNumberValue)) return;
            const platz = platzArr[newNumberValue];
            const newTraeAppl = {
                ...traeAppl,
                PlatzID: platz.PlatzID,
                PlatzBez: platz.PlatzBez,
            } as TAnprobeTraeAppl;
            changeTraeAppl(traeAppl.TraeArtiID, traeAppl.TraeApplID, newTraeAppl);
        },
        [changeTraeAppl, platzArr, traeAppl],
    );

    const theme = useAdvTheme();
    const handleRenderTitle = useAdvCallback(
        (props?: IDropdownOption<any> | undefined) => {
            if (props === undefined || props == null) return <></>;

            const index = Number(props.data);
            if (traeApplArtArr.length < index + 1) return <></>;

            const artiType = index < 0 ? traeAppl.ArtiTypeID : traeApplArtArr[index].ArtiTypeID;
            let icon = QuestionIcon;
            switch (artiType) {
                case EAdvArtiType.EMBLEM:
                    icon = EmblemIcon;
                    break;
                case EAdvArtiType.NSARTI:
                    icon = NamenschildIcon;
                    break;
                case EAdvArtiType.AENDERUNGEN:
                    icon = AenderungIcon;
                    break;
            }
            return (
                <AdvStack horizontal>
                    <AdvStackItem align="center">
                        <AdvIcon
                            {...icon}
                            styles={{
                                root: {
                                    fontSize: 14,
                                    width: 18,
                                    height: 14,
                                    marginLeft: 2,
                                    marginTop: 0,
                                },
                            }}
                        />
                    </AdvStackItem>
                    <AdvStackItem
                        shrink
                        styles={{ root: { textOverflow: "ellipsis", overflow: "hidden" } }}
                    >
                        {props.text}
                    </AdvStackItem>
                </AdvStack>
            );
        },
        [traeAppl.ArtiTypeID, traeApplArtArr],
    );
    const handleRenderOption = useAdvCallback(
        (props?: IDropdownOption<any> | undefined) => {
            if (props === undefined || props == null) return <></>;

            if (props.itemType == SelectableOptionMenuItemType.Header) {
                return (
                    <>
                        <AdvText
                            style={{
                                borderBottom: `2px solid ${theme.palette.themePrimary}`,
                                width: "100%",
                                color: theme.palette.themeSecondary,
                            }}
                        >
                            {props.text}
                        </AdvText>
                    </>
                );
            }

            return <AdvDropdownText>{props.text ?? ""}</AdvDropdownText>;
        },
        [theme.palette.themePrimary, theme.palette.themeSecondary],
    );

    const handleChoiceChange = useAdvCallback(() => {
        if (traeAppl.KdArApplChoice == EAnprobeKdArApplChoice.ChoiceRequired) {
            changeKdArApplChoice(
                traeAppl.TraeArtiID,
                traeAppl.TraeApplID,
                traeAppl.KdArApplDefaultChoice,
            );
        } else {
            const newChoice =
                traeAppl.KdArApplChoice == EAnprobeKdArApplChoice.ChoiceApply
                    ? EAnprobeKdArApplChoice.ChoiceDiscard
                    : EAnprobeKdArApplChoice.ChoiceApply;
            changeKdArApplChoice(traeAppl.TraeArtiID, traeAppl.TraeApplID, newChoice);
        }
    }, [
        changeKdArApplChoice,
        traeAppl.KdArApplChoice,
        traeAppl.KdArApplDefaultChoice,
        traeAppl.TraeApplID,
        traeAppl.TraeArtiID,
    ]);

    return (
        <>
            <AdvGridItem column={"1/2"} row={`${index + 2}/${index + 3}`}>
                <AdvDropdown
                    translatableTextLabel={toAdvText(LAN.ALTERATIONTYPE)}
                    options={traeApplArtOptions}
                    value={traeApplArtOptions.find(
                        (k) =>
                            k.key ==
                            `ddo_traeapplart_${traeAppl.TraeApplID}_${traeAppl.ApplArtikelID}_${traeAppl.KdArApplID}`,
                    )}
                    onValueChanged={handleArtChange}
                    onRenderTitle={handleRenderTitle}
                    onRenderOption={handleRenderOption}
                    disabled={
                        !isEditMode ||
                        traeAppl.TraeApplID >= 0 ||
                        traeAppl.KdArApplChoice != EAnprobeKdArApplChoice.NoChoiceRequired
                    }
                    dropdownWidth="auto"
                ></AdvDropdown>
            </AdvGridItem>
            <AdvGridItem
                column={
                    traeAppl.ArtiTypeID != EAdvArtiType.AENDERUNGEN || traeAppl.MitMass == false
                        ? "2/4"
                        : "2/3"
                }
                row={`${index + 2}/${index + 3}`}
            >
                <AdvDropdown
                    translatableTextLabel={toAdvText(LAN.ALTERATION_PLACEMENT)}
                    options={platzOptions}
                    value={platzOptions.find(
                        (k) =>
                            k.key ==
                            `ddo_traeappl_${traeAppl.TraeApplID}_platz_${traeAppl.PlatzID}`,
                    )}
                    onValueChanged={handlePlatzChange}
                    disabled={
                        !isEditMode ||
                        traeAppl.KdArApplChoice != EAnprobeKdArApplChoice.NoChoiceRequired ||
                        traeAppl.TraeApplID >= 0
                    }
                    dropdownWidth="auto"
                ></AdvDropdown>
            </AdvGridItem>
            {traeAppl.ArtiTypeID != EAdvArtiType.AENDERUNGEN || traeAppl.MitMass == false ? null : (
                <AdvGridItem column={"3/4"} row={`${index + 2}/${index + 3}`}>
                    <AdvTextInput
                        translatableTextLabel={toAdvText(LAN.ALTERATION_MEASURE)}
                        suffix="cm"
                        value={String(traeAppl.Mass)}
                        onValueChanged={handleMassChange}
                        type="number"
                        min={traeAppl.Positiv ? traeAppl.MinMass : 0}
                        max={traeAppl.MaxMass > 0 ? traeAppl.MaxMass : undefined}
                        step={traeAppl.MassVielfaches > 0 ? traeAppl.MassVielfaches : 1}
                        disabled={!isEditMode}
                        styles={{
                            field: {
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            },
                        }}
                    />
                </AdvGridItem>
            )}
            {traeAppl.KdArApplChoice == EAnprobeKdArApplChoice.NoChoiceRequired ? (
                <AdvGridItem
                    styles={{ root: { alignSelf: "end", textAlign: "center" } }}
                    column={"4/5"}
                    row={`${index + 2}/${index + 3}`}
                >
                    <AdvButton
                        style={{ minWidth: 0 }}
                        iconName={DeleteIcon.iconName}
                        onClick={() => deleteTraeAppl(traeAppl.TraeArtiID, traeAppl.TraeApplID)}
                        disabled={!isEditMode}
                    ></AdvButton>
                </AdvGridItem>
            ) : (
                <AdvGridItem
                    column={"4/5"}
                    row={`${index + 2}/${index + 3}`}
                    styles={{
                        root: { textAlign: "center", alignContent: "center", marginTop: "18px" },
                    }}
                >
                    <AdvCheckbox
                        label={LAN.ALTERATION_APPLY.text}
                        required
                        indeterminate={
                            traeAppl.KdArApplChoice == EAnprobeKdArApplChoice.ChoiceRequired
                        }
                        value={traeAppl.KdArApplChoice == EAnprobeKdArApplChoice.ChoiceApply}
                        onValueChanged={handleChoiceChange}
                        disabled={
                            !isEditMode ||
                            [
                                EAdvKdArApplAutoModus.Unknown,
                                EAdvKdArApplAutoModus.AutoNotDelRetro,
                                EAdvKdArApplAutoModus.AutoNotDelNotRetro,
                            ].indexOf(traeAppl.AutoModus) != -1
                        }
                    />
                </AdvGridItem>
            )}
        </>
    );
};
const TraeApplComp = React.memo(TraeApplCompImpl, deepCompareJSXProps);

const KdArApplToggleButton = () => {
    const [shouldShowKdArAppl, setShowKdArAppl] = useAdvRecoilState(AnprShowKdArApplAtom);
    return (
        <AdvToggle
            onText="sichtbar"
            offText="ausgeblendet"
            label={(shouldShowKdArAppl ? "Standard-Applikationen" : "Standard-Applikationen") + ":"}
            value={shouldShowKdArAppl}
            onValueChanged={() => setShowKdArAppl((old) => !old)}
        />
    );
};

const ToggleGroupboxesButton = () => {
    const [isAllGroupboxOpen, setGroupboxOpen] = useAdvRecoilState(AnprAllGroupboxOpenAtom);
    return (
        <AdvButton onClick={() => setGroupboxOpen((old) => !old)}>
            {isAllGroupboxOpen ? "Alle Gruppen schließen" : "Alle Gruppen öffnen"}
        </AdvButton>
    );
};

type TAdvTraeAnprArtProps = {
    traeArti: TTraeArti;
    traeApplArr: Array<TAnprobeTraeAppl>;
    isEditMode: boolean;
    canRemoveArticle: boolean;
    index: number;

    kdArtiArr: TAnprobeKdArti[];
    platzDict: Record<number, TAnprobeApplPlatz[]>;
    artGroeDict: Record<number, TAnprobeArtGroe[]>;
    traeApplArtDict: Record<number, TAnprobeTraeApplArt[]>;
    traeNsText?: string;

    onKdArtiChanged: (traeArtiID: number, newKdArtiID: number) => void;
    onArtGroeChanged: (traeArtiID: number, newArtGroeID: number) => void;
    onMengeChanged: (traeArtiID: number, newMenge: number) => void;
    changeKdArApplChoice: (
        traeArtiID: number,
        traeApplID: number,
        newChoice: EAnprobeKdArApplChoice,
    ) => void;
    onRemove: (traeArtiID: number) => void;

    addTraeAppl: (traeArtiID: number, kdArtiID: number) => void;
    changeTraeAppl: (traeArtiID: number, traeApplID: number, newData: TAnprobeTraeAppl) => void;
    deleteTraeAppl: (traeArtiID: number, traeApplID: number) => void;

    pageProps?: TPageComponentProps;
};
const AdvTraeAnprArtImpl = ({
    traeArti,
    traeApplArr,
    isEditMode,
    canRemoveArticle,
    index,

    kdArtiArr,
    platzDict,
    artGroeDict,
    traeApplArtDict,
    traeNsText,

    onKdArtiChanged,
    onArtGroeChanged,
    onMengeChanged,
    changeKdArApplChoice,
    onRemove,

    addTraeAppl,
    changeTraeAppl,
    deleteTraeAppl,

    pageProps,
}: TAdvTraeAnprArtProps) => {
    const isAusstattungsArtikel = traeArti.TraeArtiID < -1000 && traeArti.UrsprArtGroeID <= -1;
    const isNewArtikel =
        traeArti.TraeArtiID <= DEFAULT_TRAEARTI.TraeArtiID &&
        traeArti.UrsprArtGroeID <= DEFAULT_TRAEARTI.UrsprArtGroeID &&
        isAusstattungsArtikel == false;

    const {
        TraeArtiID: currTraeArtiID,
        ArtGroeID: currArtGroeID,
        KdArtiID: currKdArtiID,
        Menge: currMenge,
    } = traeArti;

    const kdArtiOptions = useAdvObjMemo(() => {
        const res =
            kdArtiArr.map((kdArti) => {
                return {
                    key: `dd_${currTraeArtiID}_${kdArti.KdArtiID}`,
                    text: KdArtiToStr(kdArti),
                    data: kdArti.KdArtiID,
                } as TAdvDropdownItem;
            }) ?? [];
        return res;
    }, [currTraeArtiID, kdArtiArr]);

    const artGroeOptions = useAdvObjMemo(() => {
        const res = [
            {
                key: `dd_${currTraeArtiID}_${DEFAULT_TRAEARTI.ArtGroeID}`,
                text: LAN.UNKNOWN_ARTGROE.text,
                data: DEFAULT_TRAEARTI.ArtGroeID,
                hidden: false,
                disabled: false,
            } as TAdvDropdownItem,
        ];
        res.push(
            ...getDictionaryValue(artGroeDict, currKdArtiID, []).map(
                ({ ArtGroeID, Groesse, IsAktiv }) => {
                    return {
                        key: `dd_${currTraeArtiID}_${ArtGroeID}`,
                        text: `${Groesse}`,
                        data: ArtGroeID,
                        // Inaktive ArtGroe zwar anzeigen aber nicht auswählbar
                        hidden: false,
                        disabled: !IsAktiv,
                    } as TAdvDropdownItem;
                },
            ),
        );
        return res.filter((opt) => opt.hidden == false); // Workaround hidden/disabled
    }, [artGroeDict, currKdArtiID, currTraeArtiID]);

    const groupboxHeading = useMemo(() => {
        const artikelText = isAusstattungsArtikel ? "Ausstattungsartikel" : LAN.ARTICLE.text;
        const currentKdArti = kdArtiArr.find((k) => k.KdArtiID == currKdArtiID);
        if (currentKdArti === undefined) return `${index + 1}. ${artikelText}`;

        const currentArtGroe = artGroeOptions.find((o) => o.data === traeArti.ArtGroeID);
        if (currentArtGroe === undefined || traeArti.ArtGroeID <= 0)
            return `${index + 1}. ${artikelText}: ${KdArtiToStr(currentKdArti)}`;

        return `${index + 1}. ${artikelText}: ${KdArtiToStr(currentKdArti)} ${
            LAN.ARTICLE_SIZE_SHORT.text
        }: ${currentArtGroe.text}`;
    }, [artGroeOptions, currKdArtiID, index, kdArtiArr, traeArti.ArtGroeID, isAusstattungsArtikel]);

    const isGroupboxOpen = useAdvRecoilValue(AnprAllGroupboxOpenAtom);
    const shouldShowKdArAppl = useAdvRecoilValue(AnprShowKdArApplAtom);

    const visibleTraeApplArr = traeApplArr.filter(
        (traeAppl) => shouldShowKdArAppl || traeAppl.KdArApplID < 0,
    );

    const mengeDescription =
        (traeArti.MinMengeArt ?? 0) == 0 && (traeArti.MaxMengeArt ?? 999) == 999
            ? undefined
            : `zwischen ${traeArti.MinMengeArt} und ${traeArti.MaxMengeArt}`;

    return (
        <AdvGroupbox
            heading={groupboxHeading}
            isOpen={isGroupboxOpen}
            ignoreCache
            styles={{ root: { root: { marginBottom: "4px" } } }}
        >
            <AdvStack horizontalAlign="stretch">
                <AdvStackItem align="stretch">
                    <AdvStack
                        horizontal
                        horizontalAlign="stretch"
                        verticalAlign="center"
                        tokens={{ childrenGap: 10 }}
                        styles={{
                            root: {
                                maxWidth: "100%",
                                flexWrap: "wrap",
                                justifyContent: "flex-end",
                            },
                        }}
                    >
                        <AdvStackItem
                            align="start"
                            grow={1}
                            shrink={1}
                            styles={{ root: { overflow: "hidden" } }}
                        >
                            <AdvDropdown
                                styles={{
                                    dropdownItem: {
                                        overflow: "hidden", // Scrollbar in geöffnetem Layer auf Mobile verstecken
                                    },
                                }}
                                translatableTextLabel={toAdvText(LAN.ARTICLE)}
                                options={kdArtiOptions}
                                value={kdArtiOptions.find((k) => k.data === currKdArtiID)}
                                onValueChanged={(newVal) => {
                                    if (newVal != undefined) {
                                        onKdArtiChanged(currTraeArtiID, newVal.data);
                                    }
                                }}
                                disabled={!isEditMode || !isNewArtikel}
                                dropdownWidth="auto"
                                pageProps={pageProps}
                            ></AdvDropdown>
                        </AdvStackItem>
                        <AdvStackItem grow={0} align="start">
                            <AdvDropdown
                                translatableTextLabel={toAdvText(LAN.ARTICLE_SIZE)}
                                options={artGroeOptions}
                                style={{ width: 100 }}
                                value={artGroeOptions.find((k) => k.data === currArtGroeID)}
                                onValueChanged={(newVal) => {
                                    if (newVal != undefined) {
                                        onArtGroeChanged(currTraeArtiID, newVal.data);
                                    }
                                }}
                                disabled={!isEditMode}
                                dropdownWidth="auto"
                                pageProps={pageProps}
                            ></AdvDropdown>
                        </AdvStackItem>
                        <AdvStackItem grow={0}>
                            <AdvTextInput
                                translatableTextLabel={toAdvText(LAN.ARTICLE_COUNT)}
                                type="number"
                                value={String(currMenge)}
                                translatableTextDescription={toAdvText(mengeDescription ?? "")}
                                style={{ width: 80 }}
                                min={Math.max(0, traeArti.MinMengeArt ?? 0)}
                                max={Math.min(999, traeArti.MaxMengeArt ?? 999)}
                                onValueChanged={(newVal) => {
                                    if (newVal != undefined) {
                                        onMengeChanged(currTraeArtiID, parseInt(newVal));
                                    }
                                }}
                                disabled={!isEditMode || !(traeArti.MengeAendernWeb ?? true)}
                            ></AdvTextInput>
                        </AdvStackItem>
                        <AdvStackItem
                            styles={{
                                root: {
                                    alignSelf: mengeDescription === undefined ? "end" : "center",
                                    marginTop: "2px",
                                },
                            }}
                        >
                            <AdvButton
                                iconName={DeleteIcon.iconName}
                                onClick={() => onRemove(currTraeArtiID)}
                                disabled={!isEditMode || !canRemoveArticle}
                            ></AdvButton>
                        </AdvStackItem>
                        <AdvStackItem
                            styles={{
                                root: {
                                    alignSelf: mengeDescription === undefined ? "end" : "center",
                                    marginTop: "2px",
                                },
                            }}
                        >
                            <AdvButton
                                iconName={ApplIcon.iconName}
                                style={{ minWidth: 0 }}
                                onClick={() => addTraeAppl(currTraeArtiID, currKdArtiID)}
                                disabled={
                                    !isEditMode ||
                                    currKdArtiID === undefined ||
                                    currKdArtiID < 0 ||
                                    traeApplArtDict[currKdArtiID] === undefined ||
                                    traeApplArtDict[currKdArtiID].length == 0
                                }
                            >
                                {LAN.NEW.text}
                            </AdvButton>
                        </AdvStackItem>
                    </AdvStack>
                </AdvStackItem>
                {traeApplArr.length == 0 ? null : (
                    <AdvStackItem>
                        <AdvText>{LAN.APPLICATIONS.text}:</AdvText>
                        {traeApplArr.length == visibleTraeApplArr.length ? null : (
                            <AdvText ignoreTranslation>{` (${
                                traeApplArr.length - visibleTraeApplArr.length
                            } ausgeblendet)`}</AdvText>
                        )}
                    </AdvStackItem>
                )}
                <AdvStackItem styles={{ root: { marginLeft: 5, marginTop: 0 } }}>
                    <AdvGrid
                        columns="minmax(100px, 1fr) minmax(80px, min-content) minmax(90px, min-content) minmax(60px, auto);"
                        rows="auto"
                        styles={{ root: { gridAutoRows: "auto" } }}
                    >
                        {visibleTraeApplArr.map((traeAppl, index) => {
                            return (
                                <TraeApplComp
                                    key={`traeartiappl_${traeAppl.TraeArtiID}_${traeAppl.TraeApplID}`}
                                    traeAppl={traeAppl}
                                    kdArtiID={currKdArtiID}
                                    index={index}
                                    isEditMode={isEditMode}
                                    traeApplArtArr={getDictionaryValue(
                                        traeApplArtDict,
                                        currKdArtiID,
                                        [],
                                    )}
                                    platzDict={platzDict}
                                    changeTraeAppl={changeTraeAppl}
                                    deleteTraeAppl={deleteTraeAppl}
                                    traeNsText={traeNsText}
                                    changeKdArApplChoice={changeKdArApplChoice}
                                />
                            );
                        })}
                    </AdvGrid>
                </AdvStackItem>
            </AdvStack>
        </AdvGroupbox>
    );
};
const AdvTraeAnprArt = React.memo(AdvTraeAnprArtImpl, deepCompareJSXProps);

/**
 * @param {TValue} fallbackValue Wird zurückgegeben wenn Dictionary undefined oder Key nicht gefunden
 * @param {TKey} [fallbackKey] Wenn angegeben, wird dieser Key gesucht und dessen Wert (falls gefunden) zurück gegeben bevor das FallbackValue genutzt wird.
 */
function getDictionaryValue<TKey extends keyof any, TValue>(
    dict: Record<TKey, TValue>,
    key: TKey,
    fallbackValue: TValue,
    fallbackKey?: TKey,
) {
    if (dict === undefined || dict[key] === undefined) {
        if (fallbackKey !== undefined) return getDictionaryValue(dict, fallbackKey, fallbackValue);
        return fallbackValue;
    } else return dict[key];
}

function automodusToChoice(autoModus: number) {
    switch (autoModus) {
        // Automatisch ohne Frage
        case EAdvKdArApplAutoModus.Unknown:
        case EAdvKdArApplAutoModus.AutoNotDelRetro:
        case EAdvKdArApplAutoModus.AutoNotDelNotRetro:
        case EAdvKdArApplAutoModus.AutoDel:
            return EAnprobeKdArApplChoice.ChoiceApply;
        case EAdvKdArApplAutoModus.QueYesDel: // Automatisch mit Frage, Vorschlag "JA"
            return EAnprobeKdArApplChoice.ChoiceApply;
        case EAdvKdArApplAutoModus.QueNoDel: // Automatisch mit Frage, Vorschlag "NEIN"
            return EAnprobeKdArApplChoice.ChoiceDiscard;
        default:
            return EAnprobeKdArApplChoice.ChoiceRequired;
    }
}

type TAdvTraeAnprArtListProps = TAdvCommonProperties &
    TAdvDesignerComponentProps & {
        traeArtiListWriterBindingParams?: TAdvValueBindingParams;
        isEditModeBindingParams?: TAdvValueBindingParams;

        traeArtiListReaderBindingParams?: TAdvValueBindingParams;
        traeApplListReaderBindingParams?: TAdvValueBindingParams;

        kdArtiListReaderBindingParams?: TAdvValueBindingParams;
        artGroeListReaderBindingParams?: TAdvValueBindingParams;
        platzListReaderBindingParams?: TAdvValueBindingParams;
        traeApplArtListReaderBindingParams?: TAdvValueBindingParams;
        anprobeMemoReaderBindingParams?: TAdvValueBindingParams;
        anprobeMemoWriterBindingParams?: TAdvValueBindingParams;

        groupsListReaderBindingParams?: TAdvValueBindingParams;

        dataArrayIndex?: number;
        providerKey: string;

        canAddArticleBindingParams?: TAdvValueBindingParams;
        canRemoveArticleBindingParams?: TAdvValueBindingParams;
        canAddArticle?: boolean;
        canRemoveArticle?: boolean;

        pageProps?: TPageComponentProps;
    };
const AdvTraeAnprArtListImpl = ({
    traeArtiListWriterBindingParams,
    isEditModeBindingParams,
    traeArtiListReaderBindingParams,
    traeApplListReaderBindingParams,
    kdArtiListReaderBindingParams,
    artGroeListReaderBindingParams,
    platzListReaderBindingParams,
    traeApplArtListReaderBindingParams,
    anprobeMemoReaderBindingParams,
    anprobeMemoWriterBindingParams,
    groupsListReaderBindingParams,
    dataArrayIndex = 0,
    providerKey,
    pageProps,
    canAddArticleBindingParams,
    canRemoveArticleBindingParams,
    canAddArticle: canAddArticleProp = true,
    canRemoveArticle: canRemoveArticleProp = true,
    designerData,
    compType,
    ...props
}: TAdvTraeAnprArtListProps) => {
    const [traeArtiListReader] = useAdvValueBinderAsArrayNoDataType(
        traeArtiListReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [traeApplListReader] = useAdvValueBinderAsArrayNoDataType(
        traeApplListReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [kdArtiListReader] = useAdvValueBinderAsArrayNoDataType(
        kdArtiListReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [artGroeListReader] = useAdvValueBinderAsArrayNoDataType(
        artGroeListReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [platzListReader] = useAdvValueBinderAsArrayNoDataType(
        platzListReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [traeApplArtListReader] = useAdvValueBinderAsArrayNoDataType(
        traeApplArtListReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [, settraeArtiListWriter] = useAdvValueBinderNoDataType(
        traeArtiListWriterBindingParams,
        "",
        EAdvValueDataTypes.String,
        dataArrayIndex,
    );
    const [isEditMode] = useAdvValueBinderNoDataType(
        isEditModeBindingParams,
        true,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );
    const [anprobeMemo] = useAdvValueBinder(
        anprobeMemoReaderBindingParams,
        "",
        EAdvValueDataTypes.String,
        dataArrayIndex,
    );
    const [, setAnprobeMemo, anprobeMemoAttr] = useAdvValueBinderNoDataType(
        anprobeMemoWriterBindingParams,
        "",
        EAdvValueDataTypes.String,
        dataArrayIndex,
    );
    const [groupsListReader] = useAdvValueBinderAsArrayNoDataType(
        groupsListReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [canAddArticle, , canAddArticleAttr] = useAdvValueBinder(
        canAddArticleBindingParams,
        true,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );
    const [canRemoveArticle, , canRemoveArticleAttr] = useAdvValueBinder(
        canRemoveArticleBindingParams,
        true,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const [traeNsText, setTraeNsText] = useState<string | undefined>(undefined);

    const { isLoaded: dpIsLoaded, getCurrentRecords: dpGetCurrentRecords } = useDataprovider(
        providerKey,
        {
            [EDataproviderClientOptions.AutoLoadCount]: 1,
            [EDataproviderClientOptions.GetProviderFieldsWithoutData]: false,
        },
    );
    useAdvEffect(() => {
        if (dpIsLoaded() && designerData === undefined) {
            const records = dpGetCurrentRecords();
            if (records.length > 0 && records[0] !== undefined) {
                const firstRecord = records[0];
                setTraeNsText(() => {
                    const res = (
                        (firstRecord["TRAEGER_Namenschild1"] ?? "") +
                        " " +
                        (firstRecord["TRAEGER_Namenschild2"] ?? "") +
                        " " +
                        (firstRecord["TRAEGER_Namenschild3"] ?? "") +
                        " " +
                        (firstRecord["TRAEGER_Namenschild4"] ?? "") +
                        " "
                    ).trim();
                    if (res != "") return `"${res}"`;
                    return res;
                });
            }
        }
    }, [designerData, dpGetCurrentRecords, dpIsLoaded]);

    const [traeArtiArr, setTraeArtiArr] = useState<TTraeArti[]>([]);
    useAdvEffect(() => {
        // Alle TraeArti (exist. und neue) direkt in Page-Variable schreiben
        // Komplett leere Einträge dabei ignorieren
        const filteredTraeArtiArr = traeArtiArr.filter((t) => t.KdArtiID > 0);
        // ~ console.log("MSC TraeArti", filteredTraeArtiArr);
        settraeArtiListWriter(JSON.stringify(filteredTraeArtiArr));
    }, [traeArtiArr, settraeArtiListWriter]);

    const existingTraeArtiList = useAdvObjMemo(() => {
        const res: TTraeArti[] = traeArtiListReader.map(
            (jsonVal) => JSON.parse(jsonVal) as TTraeArti,
        );
        res.forEach((traeArti) => {
            traeArti.UrsprArtGroeID = traeArti.ArtGroeID;
            traeArti.NewTraeAppl = [];
            traeArti.ModifiedTraeAppl = [];
            traeArti.DeletedTraeApplIDs = [];
        });
        return res;
    }, [traeArtiListReader]);

    useAdvEffect(() => {
        // Alle bestehenden TraeArtis dienen als "Grundlage" der Maske
        setTraeArtiArr(() => {
            return existingTraeArtiList;
        });
    }, [existingTraeArtiList]);

    const traeApplDict = useAdvObjMemo(() => {
        const res: Record<number, TAnprobeTraeAppl[]> = {};
        traeApplListReader.forEach((jsonVal) => {
            // Falls noch keine TraeArti angekommen, nichts machen
            if (traeArtiArr === undefined || traeArtiArr.length == 0) return;

            let val: TAnprobeTraeAppl = JSON.parse(jsonVal);
            val.KdArApplChoice = EAnprobeKdArApplChoice.NoChoiceRequired; // Appl existiert schon, also keine Entscheidung nötig

            const currentTraeArti = traeArtiArr.find((a) => a.TraeArtiID == val.TraeArtiID);
            if (currentTraeArti === undefined) {
                console.warn("MSC CurrentTraeArti undefined!", val);
                return;
            }
            if (currentTraeArti.DeletedTraeApplIDs.indexOf(val.TraeApplID) >= 0) return;

            const modifiedTraeAppl = currentTraeArti.ModifiedTraeAppl.find(
                (o) => o.TraeApplID === val.TraeApplID,
            );
            if (modifiedTraeAppl !== undefined) val = modifiedTraeAppl;

            if (res[val.TraeArtiID] === undefined) {
                res[val.TraeArtiID] = [val];
            } else {
                res[val.TraeArtiID].push(val);
            }
        });

        traeArtiArr.forEach((traeArti) => {
            traeArti.NewTraeAppl.forEach((traeAppl) => {
                const val: TAnprobeTraeAppl = traeAppl;

                const currentTraeArti = traeArtiArr.find((a) => a.TraeArtiID == val.TraeArtiID);
                if (
                    currentTraeArti !== undefined &&
                    currentTraeArti.DeletedTraeApplIDs.indexOf(val.TraeApplID) >= 0
                )
                    return;

                if (res[val.TraeArtiID] === undefined) {
                    res[val.TraeArtiID] = [val];
                } else {
                    res[val.TraeArtiID].push(val);
                }
            });
        });

        // ~ console.log("MSC TraeAppl", res, true);
        return res;
    }, [traeApplListReader, traeArtiArr]);

    const kdArtiArr = useAdvObjMemo(() => {
        const res: TAnprobeKdArti[] = kdArtiListReader.map(
            (jsonVal) => JSON.parse(jsonVal) as TAnprobeKdArti,
        );
        return res;
    }, [kdArtiListReader]);

    const platzDict = useAdvObjMemo(() => {
        const res: Record<number, TAnprobeApplPlatz[]> = {};
        platzListReader.forEach((jsonVal) => {
            const val: TAnprobeApplPlatz = JSON.parse(jsonVal);
            if (res[val.KdArtiID] === undefined) {
                res[val.KdArtiID] = [val];
            } else {
                res[val.KdArtiID].push(val);
            }
        });
        // ~console.log("MSC PlatzDict", res);
        return res;
    }, [platzListReader]);

    const artGroeDict = useAdvObjMemo(() => {
        const res: Record<number, TAnprobeArtGroe[]> = {};
        artGroeListReader.forEach((jsonVal) => {
            const val: TAnprobeArtGroe = JSON.parse(jsonVal);
            if (res[val.KdArtiID] === undefined) {
                res[val.KdArtiID] = [val];
            } else {
                res[val.KdArtiID].push(val);
            }
        });
        // ~ console.log("MSC ArtGroe", res);
        return res;
    }, [artGroeListReader]);

    const traeApplArtDict = useAdvObjMemo(() => {
        const res: Record<number, TAnprobeTraeApplArt[]> = {};
        traeApplArtListReader.forEach((jsonVal) => {
            const val: TAnprobeTraeApplArt = JSON.parse(jsonVal);
            if (res[val.KdArtiID] === undefined) {
                res[val.KdArtiID] = [val];
            } else {
                res[val.KdArtiID].push(val);
            }
        });
        // ~console.log("MSC TraeApplArt", res);
        return res;
    }, [traeApplArtListReader]);

    const handleAddArticle = useAdvCallback(() => {
        setTraeArtiArr((old) => {
            return [...old, { ...DEFAULT_TRAEARTI, TraeArtiID: --newTraeArtiID } as TTraeArti];
        });
    }, [setTraeArtiArr]);
    const handleRemove = useAdvCallback(
        (traeArtiID: number) => {
            setTraeArtiArr((old) => {
                const index = old.findIndex((o) => o.TraeArtiID == traeArtiID);
                if (index < 0) return old;

                const res = deepCopy(old);
                res.splice(index, 1);
                return res;
            });
        },
        [setTraeArtiArr],
    );

    const handleKdArtiChanged = useAdvCallback(
        (traeArtiID: number, newKdArtiID: number) => {
            setTraeArtiArr((old) => {
                const index = old.findIndex((o) => o.TraeArtiID == traeArtiID);
                if (index < 0) return old;

                const doesKdArtiAlrExist = old.findIndex((t) => t.KdArtiID == newKdArtiID) >= 0;

                const res = deepCopy(old);
                res[index].KdArtiID = newKdArtiID;
                res[index].ArtGroeID = DEFAULT_TRAEARTI.ArtGroeID;
                res[index].KdArApplChoices = [];
                res[index].NewTraeAppl = [];
                res[index].ModifiedTraeAppl = [];
                res[index].DeletedTraeApplIDs = [];
                res[index].Initialized = doesKdArtiAlrExist;

                // Wenn der Kundenartikel bereits vorhanden ist, können wir direkt dazugehörige
                // KdArAppl vorblenden. Andernfalls kommen die Applikationen zuerst vom Server
                if (res[index].Initialized && res[index].TraeArtiID <= 0) {
                    getDictionaryValue(traeApplArtDict, newKdArtiID, []).forEach((traeApplArt) => {
                        if (traeApplArt.KdArApplID < 0) return;

                        const choice = automodusToChoice(traeApplArt.AutoModus);
                        res[index].NewTraeAppl.push({
                            ...traeApplArt,
                            TraeArtiID: traeArtiID,
                            TraeApplID: newTraeApplID--,
                            Mass: 0,
                            KdArApplChoice: choice,
                            KdArApplDefaultChoice: choice,
                        });
                    });
                }
                return res;
            });
        },
        [traeApplArtDict],
    );
    const handleArtGroeChanged = useAdvCallback((traeArtiID: number, newArtGroeID: number) => {
        setTraeArtiArr((old) => {
            const index = old.findIndex((o) => o.TraeArtiID === traeArtiID);
            if (index < 0) return old;

            const res = deepCopy(old);
            res[index].ArtGroeID = newArtGroeID;
            return res;
        });
    }, []);
    const handleMengeChanged = useAdvCallback((traeArtiID: number, newMenge: number) => {
        setTraeArtiArr((old) => {
            const index = old.findIndex((o) => o.TraeArtiID === traeArtiID);
            if (index < 0) return old;

            const res = deepCopy(old);
            res[index].Menge = newMenge;
            return res;
        });
    }, []);
    const handleKdArApplChoiceChanged = useAdvCallback(
        (traeArtiID: number, traeApplID: number, newChoice: EAnprobeKdArApplChoice) => {
            setTraeArtiArr((old) => {
                const traeArtiIndex = old.findIndex((o) => o.TraeArtiID === traeArtiID);
                if (traeArtiIndex < 0) return old;

                const res = deepCopy(old);
                const traeApplIndex = res[traeArtiIndex].NewTraeAppl.findIndex(
                    (c) => c.TraeArtiID == traeArtiID && c.TraeApplID == traeApplID,
                );
                res[traeArtiIndex].NewTraeAppl[traeApplIndex].KdArApplChoice = newChoice;
                return res;
            });
        },
        [],
    );

    const addTraeAppl = useAdvCallback(
        (traeArtiID: number, kdArtiID: number) => {
            // ~console.log("MSC AddAppl", { traeArtiID, kdArtiID });
            setTraeArtiArr((old) => {
                const traeArtiIndex = old.findIndex((o) => o.TraeArtiID === traeArtiID);
                if (traeArtiIndex < 0) return old;

                const res = deepCopy(old);
                res[traeArtiIndex].NewTraeAppl.push({
                    ...traeApplArtDict[kdArtiID][0],
                    KdArApplID: -999,
                    Mass: 0,
                    TraeArtiID: traeArtiID,
                    TraeApplID: newTraeApplID--,
                    KdArApplChoice: EAnprobeKdArApplChoice.NoChoiceRequired,
                    KdArApplDefaultChoice: EAnprobeKdArApplChoice.NoChoiceRequired,
                    ApplKdArtiID: -999,
                    ApplArtikelID: -999,
                    PlatzID: -1,
                });
                return res;
            });
        },
        [traeApplArtDict],
    );
    const changeTraeAppl = useAdvCallback(
        (traeArtiID: number, traeApplID: number, newData: TAnprobeTraeAppl) => {
            // ~console.log("MSC ChangeAppl", { traeArtiID, traeApplID, newData });
            newData.TraeArtiID = traeArtiID;
            newData.TraeApplID = traeApplID;
            setTraeArtiArr((old) => {
                const traeArtiIndex = old.findIndex((o) => o.TraeArtiID === traeArtiID);
                if (traeArtiIndex < 0) return old;

                const res = deepCopy(old);
                const newTraeApplIndex = res[traeArtiIndex].NewTraeAppl.findIndex(
                    (a) => a.TraeApplID == traeApplID,
                );
                if (newTraeApplIndex >= 0) {
                    res[traeArtiIndex].NewTraeAppl[newTraeApplIndex] = newData;
                } else {
                    const modifiedIndex = res[traeArtiIndex].ModifiedTraeAppl.findIndex(
                        (o) => o.TraeApplID === traeApplID,
                    );
                    if (modifiedIndex < 0) {
                        res[traeArtiIndex].ModifiedTraeAppl.push(newData);
                    } else {
                        res[traeArtiIndex].ModifiedTraeAppl[modifiedIndex] = newData;
                    }
                }
                return res;
            });
        },
        [],
    );
    const deleteTraeAppl = useAdvCallback((traeArtiID: number, traeApplID: number) => {
        // ~console.log("MSC DeleteAppl", { traeArtiID, traeApplID });
        setTraeArtiArr((old) => {
            const traeArtiIndex = old.findIndex((o) => o.TraeArtiID === traeArtiID);
            if (traeArtiIndex < 0) return old;

            const res = deepCopy(old);
            const newTraeApplIndex = res[traeArtiIndex].NewTraeAppl.findIndex(
                (a) => a.TraeApplID == traeApplID,
            );
            if (newTraeApplIndex >= 0) {
                res[traeArtiIndex].NewTraeAppl.splice(newTraeApplIndex, 1);
            } else {
                res[traeArtiIndex].DeletedTraeApplIDs.push(traeApplID);
            }
            return res;
        });
    }, []);

    const { sendCallbackRequest } = useAdvSocketCallback();
    const visKdArtiIDs = useAdvObjMemo(() => {
        const res = Array.from(
            new Set(traeArtiArr.filter((t) => t.KdArtiID >= 0).map((t) => t.KdArtiID)),
        );
        // ~console.log("MSC VisKdArtiIDs", res);
        return res;
    }, [traeArtiArr]);

    useAdvEffect(() => {
        // Sofern sich die sichtbaren Kundenartikel (durch z.B. hinzufügen eines neuen Artikels) ändern,
        // wollen wir vom Server weitere Details zu diesem Kundenartikel (u.a. Platzierungen, ApplArt und ArtGroe)
        sendCallbackRequest<TAdvContractFieldDataValueChangedNtf, TSuccessClass>(
            "contract",
            "data_changed_ntf",
            {
                // Aktueller ContractName aus dem Binding nehmen
                ContractName:
                    kdArtiListReaderBindingParams?.bindingParams["contract"]?.value ?? "ctAnprobe",
                GroupIndex: 0,
                Field: "",
                Prov: "",
                FieldName: "VisKdArtiIDs",
                Value: JSON.stringify(visKdArtiIDs),
            },
        ).catch((r) => advcatch("Could not notify about the data change", r));
    }, [kdArtiListReaderBindingParams?.bindingParams, sendCallbackRequest, visKdArtiIDs]);

    useAdvEffect(() => {
        setTraeArtiArr((old) => {
            const res = deepCopy(old);
            // Bei noch nicht initialisierten TraeArti entsprechend die vorgeblendeten
            // KdArAppl noch hinterlegen (erst möglich wenn wir TraeApplArt bekommen haben...)
            res.forEach((traeArti, index) => {
                if (res[index].Initialized) return;
                res[index].Initialized = true;

                // Alle Kundenartikel-Applikationen vorblenden (nur bei neuen Trägerartikeln)
                if (res[index].TraeArtiID > 0) return;
                getDictionaryValue(traeApplArtDict, traeArti.KdArtiID, []).forEach(
                    (traeApplArt) => {
                        if (traeApplArt.KdArApplID < 0) return;

                        const choice = automodusToChoice(traeApplArt.AutoModus);
                        res[index].NewTraeAppl.push({
                            ...traeApplArt,
                            TraeArtiID: traeArti.TraeArtiID,
                            TraeApplID: newTraeApplID--,
                            Mass: 0,
                            KdArApplChoice: choice,
                            KdArApplDefaultChoice: choice,
                        });
                    },
                );
            });
            return res;
        });
    }, [traeApplArtDict]);

    const isAnprobe = compType == EComponentTypeCustom.TraeAnprArtList;
    const traeArtiGroups = useAdvObjMemo(() => {
        if (isAnprobe == false) {
            const res: Record<number, TTraeArtiGroup> = {};
            groupsListReader.forEach((jsonVal) => {
                const val: TTraeArtiGroup = JSON.parse(jsonVal);
                res[val.ID] = val;
            });
            res[-1] = { Bez: "Keine Gruppe", ID: -1, MaxMenge: 0 };
            // ~ console.log("MSC Groups", res);
            return res;
        } else return {};
    }, [isAnprobe, groupsListReader]);

    const traeArtiElements = useMemo(() => {
        if (isAnprobe == false && Object.entries(traeArtiGroups).length > 1) {
            const groupedTraeArti: Record<
                string,
                { Elements: React.JSX.Element[]; Menge: number }
            > = {};
            Object.keys(traeArtiGroups).forEach((idStr) => {
                const filteredTraeArtiArr = traeArtiArr.filter((ta) => ta.GroupID == Number(idStr));
                groupedTraeArti[idStr] = {
                    Menge: filteredTraeArtiArr
                        .map((ta) => (ta.ArtGroeID < 0 ? 0 : ta.Menge))
                        .reduce((a, b) => a + b, 0),
                    Elements: filteredTraeArtiArr.map((traeArti, index) => {
                        return (
                            <AdvStackItem key={`AdvTraeAnprArt_${traeArti.TraeArtiID}`}>
                                <AdvTraeAnprArt
                                    traeArti={traeArti}
                                    traeApplArr={getDictionaryValue(
                                        traeApplDict,
                                        traeArti.TraeArtiID,
                                        [],
                                    )}
                                    isEditMode={isEditMode}
                                    canRemoveArticle={
                                        canRemoveArticleAttr.usesBoundValue
                                            ? canRemoveArticle.val
                                            : canRemoveArticleProp
                                    }
                                    kdArtiArr={kdArtiArr}
                                    platzDict={platzDict}
                                    traeApplArtDict={traeApplArtDict}
                                    artGroeDict={artGroeDict}
                                    onKdArtiChanged={handleKdArtiChanged}
                                    onArtGroeChanged={handleArtGroeChanged}
                                    onMengeChanged={handleMengeChanged}
                                    changeKdArApplChoice={handleKdArApplChoiceChanged}
                                    onRemove={handleRemove}
                                    addTraeAppl={addTraeAppl}
                                    changeTraeAppl={changeTraeAppl}
                                    deleteTraeAppl={deleteTraeAppl}
                                    traeNsText={traeNsText}
                                    index={index}
                                    pageProps={pageProps}
                                />
                            </AdvStackItem>
                        );
                    }),
                };
            });

            return Object.entries(traeArtiGroups).map(([idStr, group]) => {
                const curMenge = groupedTraeArti[idStr].Menge;
                const maxMenge = group.MaxMenge == 0 ? curMenge : group.MaxMenge;
                return (
                    <AdvGroupbox
                        heading={Number(idStr) <= -1 ? "Keine Gruppe" : `${group.Bez}`}
                        ignoreTranslation={Number(idStr) >= 0}
                        additionalHeading={
                            Number(idStr) <= -1 ? undefined : `Menge: ${curMenge}/${maxMenge}`
                        }
                        key={`traeArtiGrp_${idStr}`}
                        canCollapse={curMenge <= maxMenge}
                    >
                        <AdvStack>
                            {group.MaxMenge == 0 ? null : (
                                <AdvStackItem
                                    align="stretch"
                                    style={{
                                        backgroundColor: curMenge <= maxMenge ? undefined : "red",
                                    }}
                                >
                                    <AdvText
                                        style={{
                                            fontWeight: curMenge <= maxMenge ? undefined : "bold",
                                            fontSize: 16,
                                        }}
                                        label="Anzahl Artikel der Gruppe: "
                                    />
                                    <AdvText
                                        ignoreTranslation
                                        style={{
                                            fontWeight: curMenge <= maxMenge ? undefined : "bold",
                                            fontSize: 16,
                                        }}
                                    >
                                        {curMenge}/{maxMenge}
                                    </AdvText>
                                </AdvStackItem>
                            )}
                            {groupedTraeArti[idStr].Elements}
                        </AdvStack>
                    </AdvGroupbox>
                );
            });
        } else {
            return traeArtiArr.map((traeArti, index) => {
                return (
                    <AdvTraeAnprArt
                        key={`AdvTraeAnprArt_${traeArti.TraeArtiID}`}
                        traeArti={traeArti}
                        traeApplArr={getDictionaryValue(traeApplDict, traeArti.TraeArtiID, [])}
                        isEditMode={isEditMode}
                        canRemoveArticle={
                            canRemoveArticleAttr.usesBoundValue
                                ? canRemoveArticle.val
                                : canRemoveArticleProp
                        }
                        kdArtiArr={kdArtiArr}
                        platzDict={platzDict}
                        traeApplArtDict={traeApplArtDict}
                        artGroeDict={artGroeDict}
                        onKdArtiChanged={handleKdArtiChanged}
                        onArtGroeChanged={handleArtGroeChanged}
                        onMengeChanged={handleMengeChanged}
                        changeKdArApplChoice={handleKdArApplChoiceChanged}
                        onRemove={handleRemove}
                        addTraeAppl={addTraeAppl}
                        changeTraeAppl={changeTraeAppl}
                        deleteTraeAppl={deleteTraeAppl}
                        traeNsText={traeNsText}
                        index={index}
                        pageProps={pageProps}
                    />
                );
            });
        }
    }, [
        addTraeAppl,
        artGroeDict,
        canRemoveArticle.val,
        canRemoveArticleAttr.usesBoundValue,
        canRemoveArticleProp,
        changeTraeAppl,
        deleteTraeAppl,
        handleArtGroeChanged,
        handleKdArApplChoiceChanged,
        handleKdArtiChanged,
        handleMengeChanged,
        handleRemove,
        isAnprobe,
        isEditMode,
        kdArtiArr,
        pageProps,
        platzDict,
        traeApplArtDict,
        traeApplDict,
        traeArtiArr,
        traeArtiGroups,
        traeNsText,
    ]);

    useAdvEffect(() => {
        // AnprobeMemo kommt aus DataProvider und SetAnprobeMemo schreibt in Page-Variable,
        // deshalb immer mindestens die Daten aus dem DP in die PageVar schreiben
        setAnprobeMemo(anprobeMemo.val);
    }, [anprobeMemo.val, setAnprobeMemo]);

    return (
        <>
            <AdvStack horizontalAlign="stretch" {...props}>
                <AdvStackItem align="stretch">
                    <AdvStack horizontal tokens={{ childrenGap: 16 }}>
                        <AdvStackItem align="start">
                            <ToggleGroupboxesButton />
                        </AdvStackItem>
                        <AdvStackItem align="start">
                            <KdArApplToggleButton />
                        </AdvStackItem>
                    </AdvStack>
                </AdvStackItem>
                <AdvStackItem align="stretch">{traeArtiElements}</AdvStackItem>
                <AdvStackItem align="stretch">
                    <AdvLoading
                        isLoading={kdArtiArr.length == 0}
                        mode={EAdvLoadingMode.HideContentWhenLoading}
                        spinnerProps={{ styles: { root: { alignItems: "start" } } }}
                    >
                        <AdvStack horizontal>
                            <AdvButton
                                primary
                                iconName={CirclePlusIcon.iconName}
                                onClick={handleAddArticle}
                                disabled={
                                    !isEditMode ||
                                    kdArtiArr.length == 0 ||
                                    (canAddArticleAttr.usesBoundValue
                                        ? !canAddArticle.val
                                        : !canAddArticleProp)
                                }
                            >
                                {LAN.ADD_ARTICLE.text}
                            </AdvButton>
                            <ToggleGroupboxesButton />
                        </AdvStack>
                    </AdvLoading>
                </AdvStackItem>
                <AdvStackItem align="stretch">
                    <hr />
                </AdvStackItem>
                {anprobeMemoAttr.usesBoundValue == false ? null : (
                    <>
                        <AdvStackItem>
                            <AdvGroupbox heading="Bemerkung" isOpen={false}>
                                <AdvStackItem align="start">
                                    <AdvTextInput
                                        value={anprobeMemo.val}
                                        multiline
                                        disabled={!isEditMode}
                                        styles={{ root: { minHeight: "15vh" } }}
                                        onValueChanged={(newValue) => {
                                            setAnprobeMemo(newValue ?? "");
                                        }}
                                    />
                                </AdvStackItem>
                            </AdvGroupbox>
                        </AdvStackItem>
                        <AdvStackItem align="stretch">
                            <hr />
                        </AdvStackItem>
                    </>
                )}
            </AdvStack>
        </>
    );
};
const AdvTraeAnprArtList = React.memo(AdvTraeAnprArtListImpl, deepCompareJSXProps);
export default AdvTraeAnprArtList;

export const AdvTraeArtiAnlageList = React.memo(AdvTraeAnprArtListImpl, deepCompareJSXProps);

import "./designable";
